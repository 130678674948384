import jsPDF from "jspdf";
import { convertStringToNumber, formatCurrency } from "../currencyConverter";
import { formatNumberWithUnit } from "../helper";
import { getPercentage } from "../helper";
import { TCO_REPORT_CHART_COLOR } from "./constants";
import { TcoFinalReport } from "v2/types/apiResponseTypes";
import PDFLandingImg from "assets/PDFLanding.png";
import LPImage1 from "assets/LPImage1.png";
import Page3Img1 from "assets/Page3Img1.png";
import Page3Img2 from "assets/Page3Img2.png";
import Page3Img3 from "assets/Page3Img3.png";
import Page3Img4 from "assets/Page3Img4.png";
import { amiriFont, rayligFont, rupeeFont } from "constants/psat";
import { logo } from "constants/tco";
import html2canvas from "html2canvas";
import {
  extractIndustryData,
  extractReportBlockData,
  extractSuccessStoryData,
  fetchLinks,
} from "../domDataExtractor";
import "./pdfStyles.scss";
import { BUSINESS_CASE_URL } from "v2/constants";
import LeafImage from "assets/leaf.png";
import { TcoReportPayloadList } from "v2/types/apiPayloadTypes";
import ValueDriversCards from "v2/assets/tco/reportpage/value-drivers.png"
import IDCFooter from "v2/assets/tco/reportpage/IDC-footer.png"
import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family";
import AIpowerdicon from "v2/assets/tco/AI-powerd-icon.svg";
import SecurityIcon from "v2/assets/tco/security-icon.svg";
import InstantAccessIcon from "v2/assets/tco/instant-access-icon.svg";
import EasyToSetupIcon from "v2/assets/tco/easy-to-setup-icon.svg";
import SustainabilityIcon from "v2/assets/tco/sustainability-icon.svg";
import BestWayToWindowsIcon from "v2/assets/tco/best-way-windows-icon.svg";

export const generatePDF = async (
  reportData: any,
  dataPerDevice: any,
  chartData: any,
  currencySymbol: any,
  years: any,
  userInput: any
) => {
  const doc = new jsPDF("p", "pt", "a4");
  const isProduction = false;

  let html = await getHTML(
    doc,
    reportData,
    dataPerDevice,
    chartData,
    currencySymbol,
    years,
    isProduction,
    userInput
  );

  if (["Kč", "zł", "₫", "₹", "₱", "₩", "฿"].indexOf(currencySymbol) >= 0) {
    let customFont;

    if (["Kč", "zł", "₫"].indexOf(currencySymbol) >= 0) {
      customFont = amiriFont;
    } else if (currencySymbol === "₹") {
      customFont = rupeeFont;
    } else if (currencySymbol === "₱" || currencySymbol === "₩") {
      customFont = rayligFont;
    }

    if (currencySymbol === "฿") {
      html = html.replaceAll(currencySymbol, "THB");
    } else {
      doc.addFileToVFS("Custom-Regular.ttf", customFont ?? "");
      doc.addFont("Custom-Regular.ttf", "Custom", "normal");
      doc.setFont("Custom");
      html = html.replaceAll(
        currencySymbol,
        `<span style="font-family: Custom;font-weight:inherit;"> ${currencySymbol} </span>`
      );
    }
  }
  const pgNum = 7;

  doc.html(html, {
    callback: async function (doc) {
      const docWidth = doc.internal.pageSize.width;
      const docHeight = doc.internal.pageSize.height;

      let totalPages = doc.internal.pages.length - 1;
      for (let i = 1; i <= totalPages; i++) {
        const noLogoIndex = [1, totalPages];

        doc.setPage(i);
        if (noLogoIndex.indexOf(i) === -1) {
          doc.addImage(logo, "png", docWidth - 195, 22.5, 165, 20);
        }
        const originalFontSize = doc.getFontSize();
        const pageNumberFontSize = 8;
        doc.setFontSize(pageNumberFontSize);
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        doc.text(`${i} / ${totalPages}`, pageWidth / 2, pageHeight - 10, {
          align: "center",
        });
        doc.setFontSize(originalFontSize);

        switch (i) {
          case 1:
            doc.link(60, 742.5, 200, 10, {
              url: BUSINESS_CASE_URL ,
            });
            doc.addImage(logo, "png", 30, 490, 165, 20);
            break;
          case pgNum:
            const canvasImageChart1 = getCanvasData("#chart1 canvas");
            if (!canvasImageChart1) {
              break;
            }
            doc.addImage(canvasImageChart1, "PNG", 30, 170, 275, 250);
            doc.link(360, 620, 100, 8, { url: BUSINESS_CASE_URL });
            break;
          case pgNum + 1:
            const canvasImageChart2 = getCanvasData("#chart2 canvas");
            if (!canvasImageChart2) {
              break;
            }
            doc.addImage(canvasImageChart2, "jpeg", 0, 170, 300, 150);
            const canvasImageChart3 = getCanvasData("#chart3 canvas");
            if (!canvasImageChart3) {
              break;
            }
            doc.addImage(canvasImageChart3, "jpeg", 30, 400, 275, 250);
            break;
          case pgNum + 2:
            const canvasImageChart4 = getCanvasData("#chart4 canvas");
            if (!canvasImageChart4) {
              break;
            }
            doc.addImage(canvasImageChart4, "jpeg", 20, 170, 275, 250);
            break;
          case pgNum + 3:
            const canvasImageChart5 = getCanvasData("#chart5 canvas");
            if (!canvasImageChart5) {
              break;
            }
            doc.addImage(canvasImageChart5, "jpeg", 30, 180, 260, 200);
            const canvasImageChart6 = getCanvasData("#chart6 canvas");
            if (!canvasImageChart6) {
              break;
            }
            doc.addImage(canvasImageChart6, "jpeg", 30, 430, 260, 200);
            break;
          case pgNum + 4:
            const canvasImageChart7 = getCanvasData("#chart7 canvas");
            if (!canvasImageChart7) {
              break;
            }
            doc.addImage(canvasImageChart7, "jpeg", 30, 170, 275, 250);
            break;
          case pgNum + 6:
            doc.addImage(logo, "png", (docWidth - 340) / 2, 730, 340, 40);
            break;
          default:
            break;
        }
      }
      doc.setProperties({
        title: "TCO Calculation Full Report"
      });
      window.open(doc.output('bloburl'))
    },
    autoPaging: "text",
    margin: [0, 0, 0, 0],
  });
};

const getCanvasData = (selector: string) => {
  const canvas = document.querySelector(selector) as HTMLCanvasElement;
  if (!canvas) {
    return;
  }
  const ctx = canvas.getContext("2d");
  if (!ctx) return;
  ctx.globalCompositeOperation = "destination-over";
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const canvasImage = canvas.toDataURL("image/jpeg", 1.0);

  return canvasImage;
};

const getHTML = async (
  doc: any,
  reportData: any,
  dataPerDevice: any,
  chartData: any,
  currencySymbol: any,
  years: any,
  isProduction: any,
  userInput: any
) => {
  const docWidth = doc.internal.pageSize.width;
  const docHeight = doc.internal.pageSize.height;
  const content =
    dataPerDevice && getPerDeviceContent(dataPerDevice, currencySymbol, years);
  const elementIds = ["itEfficiency", "employeeExperience", "costs", "savings"];
  const pages = await Promise.all(
    elementIds.map((id, index) => {
      const incluedeIndustry = index === 0;
      const includeMethodology = index === elementIds.length - 1;
      return getBussinessAssumptionPage(
        [id],
        docWidth,
        docHeight,
        incluedeIndustry,
        includeMethodology ? content : false
      );
    })
  );

  return `
      <div class="pdf">
  
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div>
              <img style="width:${docWidth}px; height:455px;" src="${PDFLandingImg}" />
          </div>
          <div class="content_section">
            <div class="mb-30" style="width:165px; height:20px;"> </div>
            <div class="flex_col" style="height:${docHeight - 565}px;">
              <div>
                <p class="report_title"> The Business Case </p>
                <p class="title_1"> Total Cost of Ownership (TCO) Report </p>
              </div>
              <div>
                <p style="margin-bottom:10px">
                  The TCO tool provides you with a report that includes estimates of potential costs, savings, and ROI you may achieve by replacing other PC deviceswith Surface devices and M365. Default values provided in the TCO tool are based on the study "<a>Evaluating the Business Case of Microsoft Surface</a>" conducted by IDC and commissioned by Microsoft in 2022.
                </p>
                <p>
                  The TCO report provided to you is for informational purposes only. You should not interpret the report you receive to be a commitment on the part of Microsoft; actual costs and savings may vary based on your location, purchase method, deployment, usage, and other factors. Neither Microsoft nor IDC makes any representations or warranties, express or implied, as to the information within this website and report.
                </p>
              </div>
            </div>
            
          </div>
        </div>
        ${pages.join("\n")}

        <div style="width:${docWidth}px; height:${docHeight}px;">
        <div class="content_section">
          <p class="main_title"> Methodology </p>
          <div style="height:140px;margin:0; margin-bottom: 10px; padding:0 10px">
              <img style="width:${
                docWidth - 82.5
              }px; height:140px;" src="${Page3Img1}" />
          </div>
          <div style="height:125px;margin:0;  margin-bottom: 15px; padding:0 10px; text-align: center;">
              <img style="width:${
                (docWidth - 82.5) / 2
              }px; height:125px;" src="${Page3Img2}" />
          </div>
          <div style="height:220px;margin:0;padding:0 10px">
              <img style="width:${
                docWidth - 82.5
              }px; height:220px;" src="${Page3Img3}" />
          </div>
          <div style="height:225px;margin:0;padding:0 10px">
              <img style="width:${
                docWidth - 82.5
              }px; height:225px;" src="${Page3Img4}" />
          </div>

        </div>
      </div>
  
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div class="content_section" >
              <p class="main_title">The Costs</p>
              <p class="main_title_sub"> OVER ${years} YEARS</p>
              <div>
                  <p class="sub_title" style="width:${
                    docWidth - 62.5
                  }px;"> ${years}-Year Total Costs</p>
                  ${getBigTableChartHTML(
                    reportData,
                    "totalCosts",
                    currencySymbol,
                    docWidth,
                    "",
                    years
                  )}
                  <p style="font-size:6px; margin-top:20px">
                    Unless you entered your own values using the Assumptions Editor, the values for Other PC Devices are based on the study 
                    “<a>Microsoft Surface for Business Use Case</a>”
                    conducted by IDC, commissioned by Microsoft in 2022.
                   </p>
              </div>
          </div>
        </div>
  
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div class="content_section" >
              <p class="main_title">The Savings</p>
              <p class="main_title_sub"> OVER ${years} YEARS</p>
              <div>
                  <p class="sub_title" style="width:${
                    docWidth - 62.5
                  }px;"> Total Surface Savings And Benefits</p>
                  <div class="chart_sec">
                    <div>
                        <div style="width:275px; height:150px"> </div>
                    </div>
                    <div style="width:${docWidth - 337.5}px;">
                          <p class="price_text"> ${formatCurrency(
                            reportData.savings,
                            currencySymbol,
                            0
                          )}</p>
                          
                          <table style="width:100%;font-size:9px">
                            <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                              <td style="display:flex; align-items: center;"> 
                                <div class="marker-355 marker" style="background-color: rgb(0, 75, 28);"></div>
                                Total Direct Savings
                              </td>
                              <td> ${formatCurrency(
                                reportData.totalSavings?.savings,
                                currencySymbol,
                                0
                              )} </td>
                              
                            </tr>
                            <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                              <td style="display:flex; align-items: center;"> 
                                <div class="marker-355 marker" style="background-color: rgb(186, 216, 10);"></div>
                                Total IT Efficiency Benefits 
                              </td>
                              <td> ${formatCurrency(
                                reportData.itSimplification?.savings,
                                currencySymbol,
                                0
                              )} </td>
                              
                            </tr>
                            <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                              <td style="display:flex; align-items: center;"> 
                                <div class="marker-355 marker" style="background-color: rgb(16, 124, 16);"></div>
                                Total Employee Experience Benefits 
                              </td>
                              <td> ${formatCurrency(
                                reportData.employeeEmpowerment?.savings,
                                currencySymbol,
                                0
                              )} </td>
                              
                            </tr>
                            <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
                              <td style="display:flex; align-items: center;"> 
                                <div class="marker-355 marker" style="background-color: rgb(80, 196, 51);"></div>
                                Total Sustainability Savings
                              </td>
                              <td> ${formatCurrency(
                                reportData.sustainability?.savings,
                                currencySymbol,
                                0
                              )} </td>
                              
                            </tr>
                            <tr>
                              <td> 
                                <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
                                Total 
                              </td>
                              <td> ${formatCurrency(
                                reportData.savings,
                                currencySymbol,
                                0
                              )} </td>
                              
                            </tr>
                          </table>
                          
                    </div>
                  </div>
              </div>
              <div>
                  <p class="sub_title" style="width:${
                    docWidth - 62.5
                  }px;"> ${years} Year Total Direct Savings</p>
                  ${getBigTableChartHTML(
                    reportData,
                    "totalSavings",
                    currencySymbol,
                    docWidth,
                    "",
                    years
                  )}
              </div>
          </div>
        </div>
        
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div class="content_section" >
            <p class="main_title">The Savings</p>
            <p class="main_title_sub"> OVER ${years} YEARS</p>
            <div>
                  <p class="sub_title" style="width:${
                    docWidth - 62.5
                  }px;"> IT Efficiency</p>
                  <div class="chart_sec">
                    <div>
                        <div style="width:270px; height:250px" ></div>
                    </div>
                    <div style="width:${docWidth - 337.5}px;">
                        <p class="price_text"> ${formatCurrency(
                          reportData.itSimplification?.savings,
                          currencySymbol,
                          0
                        )}</p>
                        <p class="title_text"> Expected IT-Related Savings </p>
                        <p class="price_text"> ${formatNumberWithUnit(
                          reportData.itSimplification?.hoursSavings,
                          null,
                          0
                        )}</p>
                        <p class="title_text"> Expected Hour Savings Per IT Employee </p>
                        <p class="desc_text">
                          Your ${years} year total IT Efficiency Benefits take into consideration device consolidation, help desk support, time to resolve security incidents, ongoing maintenance, IT deployment costs, IT training costs, and ongoing IT security. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.   
                        </p>
                    </div>
                  </div>
  
                    <div style="width:${docWidth - 337.5}px; margin-top: 20px">
                      ${getTwoColTableHTML(
                        reportData,
                        "itSimplification",
                        currencySymbol,
                        docWidth
                      )}
                  </div>
            </div>
          </div>
        </div>
  
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div class="content_section" >
            <p class="main_title">The Savings</p>
            <p class="main_title_sub"> OVER ${years} YEARS</p>
            <div>              
              <p class="sub_title" style="width:${
                docWidth - 62.5
              }px;"> IT Hours Saved Due To Reduced Help Desk And Security Incidents</p>
              <div >
                <div class="chart_sec">
                    <div style="width:275px; height:250px"> 
                      <p class="desc_text" style="width: 275px; text-align: center"> Help Desk Incidents  </p>
                    </div>
                    <div class="vh-center" style="height:250px; padding-left: 15px;">
                      <div>
                        <p class="price_text"> ${formatNumberWithUnit(
                          chartData.incidentsSaving.helpDesk?.hoursSavings,
                          null,
                          0
                        )} hours</p>
                        <p class="title_text"> Fewer Helpdesk Incidents </p>
                      </div>
                    </div>
                </div>
                <div class="chart_sec">
                    
                    <div style="width:275px; height:250px"> 
                      <p class="desc_text" style="width: 275px; text-align: center">Security Incidents </p>
                    </div>
                    <div class="vh-center" style="height:250px; padding-left: 15px;">
                      <div>
                        <p class="price_text"> ${formatNumberWithUnit(
                          chartData.incidentsSaving.security?.hoursSavings,
                          null,
                          0
                        )} hours</p>
                        <p class="title_text"> Fewer Security Incidents </p> 
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div class="content_section" >
            <p class="main_title">The Savings</p>
            <p class="main_title_sub"> OVER ${years} YEARS</p>
            <div>
                <div>
                    <p class="sub_title" style="width:${
                      docWidth - 62.5
                    }px;"> Employee Experience Savings</p>
                    <div class="chart_sec">
                      <div>
                        <div style="width:275px; height:250px"> </div>
                      </div>
                      <div style="width:${docWidth - 337.5}px;">
                            <p class="price_text"> ${formatCurrency(
                              reportData.employeeEmpowerment.savings,
                              currencySymbol,
                              0
                            )}</p>
                            <p class="title_text"> Expected Savings </p>
                            <p class="price_text"> ${formatNumberWithUnit(
                              reportData.employeeEmpowerment.hoursSavings,
                              null,
                              0
                            )}</p>
                            <p class="title_text"> Expected Hour Savings Per Employee  </p>
                            <p class="desc_text">
                              Your ${years} year total Employee Experience Benefits take into consideration productivity gains from increased mobility, time spent on help desk resolution, meeting time saved, time spent on security incident resolution, Surface deployment to employees, employee retention, and boot up time. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.         
                            </p>
                            
                      </div>
                    </div> 
                    <div style="margin-top:20px">
                        ${getTwoColTableHTML(
                          reportData,
                          "employeeEmpowerment",
                          currencySymbol,
                          docWidth
                        )}
                    </div>               
                </div>
            </div>
          </div>
        </div>
  
        <div style="width:${docWidth}px; height:${docHeight}px;">
          <div class="content_section" >
            <p class="main_title">The Savings</p>
            <p class="main_title_sub"> OVER ${years} YEARS</p>
            <div>
                <div>
                    <p class="sub_title" style="width:${
                      docWidth - 62.5
                    }px;"> Sustainability Savings</p>
                    <div class="chart_sec">
                      <div style="width:${docWidth - 337.5}px;">
                        <p class="price_text"> ${formatCurrency(
                          reportData.sustainability.savings,
                          currencySymbol,
                          0
                        )}</p>
                        <p class="title_text"> Expected Savings </p>
                      </div>
                    </div> 
                    <div>
                        ${getEcoTableHTML(
                          reportData,
                          currencySymbol,
                          docWidth,
                          userInput
                        )}
                    </div>               
                </div>
            </div>
          </div>
        </div>
  
        <div style="width:${docWidth}px; height:${docHeight - 15}px;">
          <div style="height:440px;margin:10px 0 0;">
              <img style="width:${docWidth}px; height:440px;" src="${LPImage1}" />
          </div>
          <div class="vh-center" style="width:${docWidth}px; height:${
    docHeight - 675
  }px;">
            <div  style="width:340px;" ></div>
          </div>
        </div>
  
      </div>
    `;
};

const getEcoTableHTML = (
  reportData: TcoFinalReport,
  currencySymbol: string,
  docWidth: number,
  userInput: any
) => {
  const bubbleColors = {
    sustainability: TCO_REPORT_CHART_COLOR.employee,
  };

  let sustainabilityUserInput = userInput.sustainability;
  let quantity =
    sessionStorage.getItem("showPerDevice") === "true"
      ? 1
      : convertStringToNumber(userInput.deviceMixPortfolio[0]?.quantity);
  let years = sessionStorage.getItem("years")
    ? Number(sessionStorage.getItem("years"))
    : 3;

  const dataObject = {
    Energy: {
      surfaceCol2:
        convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) *
        quantity *
        years,
      otherPcCol2:
        convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) *
        quantity *
        years,
      savingsCol2:
        convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) *
          quantity *
          years -
        convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) *
          quantity *
          years,
    },
    CarbonEmissions: {
      surfaceCol2:
        convertStringToNumber(
          sustainabilityUserInput.CarbonEmissions.surfaceValue
        ) *
        quantity *
        years,
      otherPcCol2:
        convertStringToNumber(
          sustainabilityUserInput.CarbonEmissions.otherPcValue
        ) *
        quantity *
        years,
      savingsCol2:
        convertStringToNumber(
          sustainabilityUserInput.CarbonEmissions.otherPcValue
        ) *
          quantity *
          years -
        convertStringToNumber(
          sustainabilityUserInput.CarbonEmissions.surfaceValue
        ) *
          quantity *
          years,
    },
  };

  return `
    <table style="width:${docWidth - 62.5}px;font-size:9px">
    <tr>
        <th > </th>
        <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Surfaces</th>
        <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Other PCs </th>
        <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Savings </th>
    </tr>
    ${reportData.sustainability.rows
      ?.map(
        (item, i) => `
          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
              <td style="display:flex; align-items: center;"> 
                <div class="marker-355 marker" style="background-color: ${
                  bubbleColors.sustainability[i]
                } ;"></div>
                ${item.label}
              </td>
              <td> ${formatCurrency(item.surfaceValue, currencySymbol, 0)} </td>
              <td> ${
                item.label === "Total Energy Consumption Savings"
                  ? `${dataObject.Energy.surfaceCol2} Kwh`
                  : `${dataObject.CarbonEmissions.surfaceCol2} MTCO2`
              } </td>
              <td> ${formatCurrency(item.pcValue, currencySymbol, 0)} </td>
              <td> ${
                item.label === "Total Energy Consumption Savings"
                  ? `${dataObject.Energy.otherPcCol2} Kwh`
                  : `${dataObject.CarbonEmissions.otherPcCol2} MTCO2`
              } </td>
              <td> ${formatCurrency(item.savings, currencySymbol, 0)} </td>
              <td> ${
                item.label === "Total Energy Consumption Savings"
                  ? `${dataObject.Energy.savingsCol2} Kwh`
                  : `${dataObject.CarbonEmissions.savingsCol2} MTCO2`
              } </td>
          </tr>
      `
      )
      .join("")}
    <tr>
      <td valign="middle"> 
        <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
        Total 
      </td>
      <td> ${formatCurrency(
        reportData.sustainability.surfaceValue,
        currencySymbol,
        0
      )} </td>
      <td></td>
      <td> ${formatCurrency(
        reportData.sustainability.pcValue,
        currencySymbol,
        0
      )} </td>
      <td></td>
      <td> ${formatCurrency(
        reportData.sustainability.savings,
        currencySymbol,
        0
      )} </td>
      <td></td>
    </tr>
  </table>
    `;
};

const getBigTableChartHTML = (
  reportData: any,
  fieldName: any,
  currencySymbol: any,
  docWidth: any,
  selector: any,
  years: any
) => {
  type BubbleColors = {
    totalCosts: string[];
    totalSavings: string[];
  };
  const bubbleColors: BubbleColors = {
    totalCosts: TCO_REPORT_CHART_COLOR.totalCosts,
    totalSavings: TCO_REPORT_CHART_COLOR.totalSavings,
  };

  const subTitle = {
    totalCosts: "Expected Costs ",
    totalSavings: "Expected Savings ",
  };

  const description = {
    totalCosts:
      "These costs take into consideration device costs, accessories costs, M365 Licensing costs, and extended warranty & maintenance costs in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.",
    totalSavings: `Your ${years} year total savings take into consideration the increased residual value, reduced support costs, and accessories savings in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
  };

  const dataRowsHTML = reportData[fieldName].rows
    .map(
      (item: any, i: number) => `
          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
              <td style="display:flex; align-items: center;"> 
                <div class="marker-355 marker" style="background-color: ${
                  bubbleColors[fieldName as keyof BubbleColors][i]
                } ;"></div>
                ${item.label}
              </td>
              <td> ${formatCurrency(item.surfaceValue, currencySymbol, 0)} </td>
              <td> ${formatCurrency(item.pcValue, currencySymbol, 0)} </td>
              <td> ${formatCurrency(item.savings, currencySymbol, 0)} </td>
          </tr>
      `
    )
    .join("");

  return `
    <div class="chart_sec" style="margin-bottom:5px">
      <div>
          <div style="width:275px; height:255px"> </div>
      </div>
      <div style="width:${docWidth - 337.5}px;">
            <p class="price_text"> ${formatCurrency(
              fieldName === "totalSavings"
                ? reportData[fieldName].savings
                : reportData[fieldName].surfaceValue,
              currencySymbol,
              0
            )}</p>
            <p class="title_text"> ${
              subTitle[fieldName as keyof typeof subTitle]
            } </p>
            <p class="desc_text">
             ${description[fieldName as keyof typeof description]}            
            </p>
            
      </div>
    </div>
    <table style="width:${docWidth - 62.5}px;font-size:10px">
      <tr>
          <th > </th>
          <th style="width:100px"> Surfaces</th>
          <th style="width:100px"> Other PCs </th>
          <th style="width:100px"> Savings </th>
      </tr>
      ${dataRowsHTML}
      <tr>
        <td> 
          <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
          Total 
        </td>
        <td> ${formatCurrency(
          reportData[fieldName].surfaceValue,
          currencySymbol,
          0
        )} </td>
        <td> ${formatCurrency(
          reportData[fieldName].pcValue,
          currencySymbol,
          0
        )} </td>
        <td> ${formatCurrency(
          reportData[fieldName].savings,
          currencySymbol,
          0
        )} </td>
      </tr>
    </table>
    `;
};

const getPerDeviceContent = (
  data: TcoFinalReport,
  currencySymbol: any,
  noOfYears = 3
) => {
  const reportData = {
    deviceCosts: data.totalCosts?.rows?.find(
      (item) => item.label === "Total Device Costs"
    ) || {
      label: "Total Device Costs",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    M365LicenseCost: data.totalCosts?.rows?.find(
      (item) => item.label === "Total M365 Licensing Costs"
    ) || {
      label: "Total M365 Licensing Costs",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    accessoriesCosts: data.totalCosts?.rows?.find(
      (item) => item.label === "Total Accessories Cost"
    ) || {
      label: "Total Accessories Cost",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    maintenanceCosts: data.totalCosts?.rows?.find(
      (item) => item.label === "Total Extended Warranty & Maintenance Costs"
    ) || {
      label: "Total Extended Warranty & Maintenance Costs",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    accessoriesSavings: data.totalSavings?.rows?.find(
      (item) => item.label === "Accessories Savings"
    ) || {
      label: "Accessories Savings",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    incResidualVal: data.totalSavings?.rows?.find(
      (item) => item.label === "Increased Residual Value"
    ) || {
      label: "Increased Residual Value",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedSupport: data.totalSavings?.rows?.find(
      (item) => item.label === "Reduced Support"
    ) || {
      label: "Reduced Support",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    deviceConsolidation: data.itSimplification?.rows?.find(
      (item) => item.label === "Device Consolidation"
    ) || {
      label: "Device Consolidation",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedTimeForSecurityIncident: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced Time for Security Incident Response"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced Time for Security Incident Response",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedHDSupport: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced Helpdesk Support"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced Helpdesk Support",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedOngoingMaint: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced Ongoing Maintenance"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced Ongoing Maintenance",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedITDeploymentCost: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced IT Deployment Costs"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced IT Deployment Costs",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedTimeForSecurity: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced IT Staff Time for Ongoing Security"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced IT Staff Time for Ongoing Security",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    trainingCost: data.itSimplification?.rows?.find(
      (item) => item.label === "IT Training Costs"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "IT Training Costs",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    fasterSurfDeployment: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Faster Surface Deployment to Employees"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Faster Surface Deployment to Employees",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    higherEmployeeRetention: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Higher Employee Retention"
    ) || {
      label: "Higher Employee Retention",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    reducedEmpTimeLoss: data.employeeEmpowerment?.rows?.find(
      (item) =>
        item.label === "Reduced Employee Time Lost to Security Incidents"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced Employee Time Lost to Security Incidents",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    fasterBootupTime: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Faster Bootup Time"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Faster Bootup Time",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    productivityGains: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Productivity Gains from Increased Mobility"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Productivity Gains from Increased Mobility",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    employeeTimeLosttoHelpdesk: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Reduced Employee Time Lost to Helpdesk"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Reduced Employee Time Lost to Helpdesk",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    meetingTime: data.employeeEmpowerment.rows?.find(
      (item) => item.label === "Meeting Time Saved"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Meeting Time Saved",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    bootupTime: data.employeeEmpowerment.rows?.find(
      (item) => item.label === "Faster Bootup Time"
    ) || {
      surfaceHours: 0,
      pcHours: 0,
      hoursSavings: 0,
      label: "Faster Bootup Time",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    energySavings: data.sustainability.rows?.find(
      (item) => item.label === "Total Energy Consumption Savings"
    ) || {
      label: "Total Energy Consumption Savings",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
    carbonSavings: data.sustainability.rows?.find(
      (item) => item.label === "Total Carbon Emissions Savings"
    ) || {
      label: "Total Carbon Emissions Savings",
      surfaceValue: 0,
      pcValue: 0,
      savings: 0,
    },
  };

  const content = {
    avgSavings: formatCurrency(data.totalSavings.savings, currencySymbol, 0),
    ROI: data.ROI % 1 === 0 ? data.ROI : data.ROI.toFixed(1),
    additionalSavings: formatCurrency(
      data.itSimplification.savings + data.employeeEmpowerment.savings,
      currencySymbol,
      0
    ),
    totalCosts: {
      data: [
        {
          value: formatCurrency(
            reportData.deviceCosts.surfaceValue,
            currencySymbol,
            0
          ),
          label: "Surface Device",
          subLabel: null,
        },
        {
          value: formatCurrency(
            reportData.M365LicenseCost.surfaceValue,
            currencySymbol,
            0
          ),
          label: "Microsoft 365 Subscription",
          subLabel: `${formatCurrency(
            reportData.M365LicenseCost.surfaceValue / (12 * noOfYears),
            currencySymbol,
            0
          )} per user per month. License cost doesn't change across device manufacturers`,
        },
        {
          value: formatCurrency(
            reportData.accessoriesCosts.surfaceValue,
            currencySymbol,
            0
          ),
          label: "Accessories",
          subLabel: null,
        },
        {
          value: formatCurrency(
            reportData.maintenanceCosts.surfaceValue,
            currencySymbol,
            0
          ),
          label: "Extended Warranty & Maintenance",
          subLabel: null,
        },
      ],
      total: {
        value: formatCurrency(data.totalCosts.surfaceValue, currencySymbol, 0),
        label: "Total Cost per Surface device",
        subLabel: `<br/>(incl. accessories) over ${noOfYears} years`,
      },
    },
    totalSavings: {
      data: [
        {
          value: formatCurrency(
            reportData.incResidualVal.savings,
            currencySymbol,
            0
          ),
          label: "Increased Residual Value",
          subLabel: `${getPercentage(
            reportData.incResidualVal.savings,
            reportData.incResidualVal.pcValue
          )}% higher than other PC devices`,
        },
        {
          value: formatCurrency(
            reportData.reducedSupport.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Support",
          subLabel: `${getPercentage(
            reportData.reducedSupport.savings,
            reportData.reducedSupport.pcValue
          )}% lower third-party support & security costs`,
        },
        {
          value: formatCurrency(
            reportData.accessoriesSavings.savings,
            currencySymbol,
            0
          ),
          label: "Accessories Savings",
          subLabel:
            "Fewer hybrid meeting accessories (Camera, Lights, Headset)",
        },
      ],
      total: {
        value: formatCurrency(data.totalSavings.savings, currencySymbol, 0),
        label: "Total Savings per Surface device",
        subLabel: `<br/>(incl. accessories) over ${noOfYears} years`,
      },
    },
    itSimplification: {
      data: [
        {
          value: formatCurrency(
            reportData.deviceConsolidation.savings,
            currencySymbol,
            0
          ),
          label: "Device Consolidation",
          subLabel: `${getPercentage(
            reportData.deviceConsolidation.savings,
            reportData.deviceConsolidation.pcValue
          )}% of organizations consolidated 2 .3 devices on avg`,
        },
        {
          value: formatCurrency(
            reportData.reducedHDSupport.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Helpdesk Support",
          subLabel: `${getPercentage(
            reportData.reducedHDSupport.savings,
            reportData.reducedHDSupport.pcValue
          )}% fewer helpdesk incidents`,
        },
        {
          value: formatCurrency(
            reportData.reducedOngoingMaint.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Ongoing Maintenance",
          subLabel: `${getPercentage(
            reportData.reducedOngoingMaint.savings,
            reportData.reducedOngoingMaint.pcValue
          )}% reduced IT Staff time spent on maintenance`,
        },
        {
          value: formatCurrency(
            reportData.reducedTimeForSecurityIncident.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Time for Security Incident Response",
          subLabel: `${getPercentage(
            reportData.reducedTimeForSecurityIncident.savings,
            reportData.reducedTimeForSecurityIncident.pcValue
          )}% fewer security incidents`,
        },
        {
          value: formatCurrency(
            reportData.reducedITDeploymentCost.savings,
            currencySymbol,
            0
          ),
          label: "Reduced IT Deployment Costs",
          subLabel: `${getPercentage(
            reportData.reducedITDeploymentCost.savings,
            reportData.reducedITDeploymentCost.pcValue
          )}% reduced IT Staff time spent on deployment`,
        },
        {
          value: formatCurrency(
            reportData.reducedTimeForSecurity.savings,
            currencySymbol,
            0
          ),
          label: "Reduced IT Staff Time for Ongoing Security",
          subLabel: `${getPercentage(
            reportData.reducedTimeForSecurity.savings,
            reportData.reducedTimeForSecurity.pcValue
          )}% reduced IT Staff time spent on ongoing security`,
        },
        {
          value: formatCurrency(
            reportData.trainingCost.savings,
            currencySymbol,
            0
          ),
          label: "Higher IT Training Costs",
          subLabel: `${getPercentage(
            reportData.trainingCost.savings,
            reportData.trainingCost.pcValue
          )}% increased IT Staff time due to Surface training`,
        },
      ],
      total: {
        value: formatCurrency(data.itSimplification.savings, currencySymbol, 0),
        label: "Total IT Benefit per Surface device",
        subLabel: `<br/>(over ${noOfYears} years)`,
      },
    },
    employeeEmpowerment: {
      data: [
        {
          value: formatCurrency(
            reportData.productivityGains.savings,
            currencySymbol,
            0
          ),
          label: "Productivity Gains from Increased Mobility",
          subLabel: "+11.6 hours Employee-productivity time per year",
        },
        {
          value: formatCurrency(
            reportData.employeeTimeLosttoHelpdesk.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Employee Time Lost to Helpdesk",
          subLabel: "+5.5 hours Employee-productivity time per year",
        },
        {
          value: formatCurrency(
            reportData.meetingTime.savings,
            currencySymbol,
            0
          ),
          label: "Meeting Time Saved",
          subLabel: `+7.7 hours Employee-productivity time per year`,
        },
        {
          value: formatCurrency(
            reportData.reducedEmpTimeLoss.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Employee Time Lost to Security Incidents",
          subLabel: `${getPercentage(
            reportData.reducedEmpTimeLoss.savings,
            reportData.reducedEmpTimeLoss.pcValue
          )}% reduced time waiting for incident resolution`,
        },
        {
          value: formatCurrency(
            reportData.fasterSurfDeployment.savings,
            currencySymbol,
            0
          ),
          label: "Faster Surface Deployment to Employees",
          subLabel: `${getPercentage(
            reportData.fasterSurfDeployment.savings,
            reportData.fasterSurfDeployment.pcValue
          )}% reduced Employee time waiting for device`,
        },
        {
          value: formatCurrency(
            reportData.higherEmployeeRetention.savings,
            currencySymbol,
            0
          ),
          label: "Higher Employee Retention",
          subLabel: `${getPercentage(
            reportData.higherEmployeeRetention.savings,
            reportData.higherEmployeeRetention.pcValue
          )}% increased retention of Surface-utilizing employees`,
        },
        {
          value: formatCurrency(
            reportData.bootupTime.savings,
            currencySymbol,
            0
          ),
          label: "Faster Bootup Time",
          subLabel: `${getPercentage(
            reportData.fasterBootupTime.savings,
            reportData.fasterBootupTime.pcValue
          )}% faster boot time, 21% fewer reboots with Surface`,
        },
      ],
      total: {
        value: formatCurrency(
          data.employeeEmpowerment.savings,
          currencySymbol,
          0
        ),
        label: "Total Employee Benefit per Surface device",
        subLabel: `(over ${noOfYears} years)`,
      },
    },
    sustainability: {
      totalSavings: formatCurrency(
        data.sustainability.savings,
        currencySymbol,
        0
      ),
      energySavings: formatCurrency(
        reportData.energySavings.savings,
        currencySymbol,
        0
      ),
      carbonSavings: formatCurrency(
        reportData.carbonSavings.savings,
        currencySymbol,
        0
      ),
      isNotEmpty: data.sustainability.rows
        ? data.sustainability.rows.length > 0
        : false,
    },
  };

  return content;
};

export const getPerDeviceContentMulti = (
  multiDeviceTotaldata: TcoReportPayloadList,
  multiDevicePerUnitdata: TcoFinalReport[],
  data: TcoFinalReport,
  currencySymbol: any,
  noOfYears = 3,
) => {
  if (!multiDevicePerUnitdata.length || !multiDeviceTotaldata.payloadList.length) {
    return {
      avgSavings: '',
      ROI: '',
      additionalSavings: '',
      devicesAndOtherCosts: '',
      totalCosts: { data: [], perDeviceTotal: '', total: { value: '', label: '', subLabel: '' }, allDeviceTotal: { value: '', label: '', subLabel: '' } },
      totalSavings: { data: [], total: { value: '', label: '', subLabel: '' } },
      itSimplification: { data: [], total: { value: '', label: '', subLabel: '' } },
      employeeEmpowerment: { data: [], total: { value: '', label: '', subLabel: '' } },
      sustainability: { totalSavings: '', energySavings: '', carbonSavings: '', isNotEmpty: false },
      totalSavingsAndBenefits: ''
    };
  }
  const reportData = {
    deviceCosts: multiDevicePerUnitdata.map(device => {
      return device.totalCosts?.rows?.find(
        (item) => item.label === "Total Device Costs"
      )
    }),
    M365LicenseCost: data.totalCosts?.rows?.find(
      (item) => item.label === "Microsoft 365 Subscription"
    ),
    accessoriesCosts: data.totalCosts?.rows?.find(
      (item) => item.label === "Accessories"
    ) ,
    maintenanceCosts: data.totalCosts?.rows?.find(
      (item) => item.label === "Extended Warranty & Maintenance"
    ),
    accessoriesSavings: data.totalSavings?.rows?.find(
      (item) => item.label === "Accessories Savings"
    ),
    incResidualVal: data.totalSavings?.rows?.find(
      (item) => item.label === "Increased Residual Value"
    ),
    reducedSupport: data.totalSavings?.rows?.find(
      (item) => item.label === "Reduced Support"
    ),
    deviceConsolidation: data.itSimplification?.rows?.find(
      (item) => item.label === "Device Consolidation"
    ),
    reducedTimeForSecurityIncident: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced Time for Security Incident Response"
    ),
    reducedHDSupport: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced Helpdesk Support"
    ),
    reducedOngoingMaint: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced Ongoing Maintenance"
    ),
    reducedITDeploymentCost: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced IT Deployment Costs"
    ),
    reducedTimeForSecurity: data.itSimplification?.rows?.find(
      (item) => item.label === "Reduced IT Staff Time for Ongoing Security"
    ),
    trainingCost: data.itSimplification?.rows?.find(
      (item) => item.label === "Higher IT Training Cost"
    ),
    fasterSurfDeployment: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Faster Surface Deployment to Employees"
    ) ,
    higherEmployeeRetention: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Higher Employee Retention"
    ),
    reducedEmpTimeLoss: data.employeeEmpowerment?.rows?.find(
      (item) =>
        item.label === "Reduced Employee Time Lost to Security Incidents"
    ) ,
    fasterBootupTime: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Faster Boot-up Time"
    ),
    productivityGains: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Productivity Gains from Increased Mobility"
    ) ,
    employeeTimeLosttoHelpdesk: data.employeeEmpowerment?.rows?.find(
      (item) => item.label === "Reduced Employee Time Lost to Helpdesk"
    ),
    meetingTime: data.employeeEmpowerment.rows?.find(
      (item) => item.label === "Meeting Time Saved"
    ),
    bootupTime: data.employeeEmpowerment.rows?.find(
      (item) => item.label === "Faster Boot-up Time"
    ),
    energySavings: data.sustainability.rows?.find(
      (item) => item.label === "Total Energy Consumption Savings"
    ),
    carbonSavings: data.sustainability.rows?.find(
      (item) => item.label === "Total Carbon Emissions Savings"
    ),
  };

  const totalDeviceQuantity = multiDeviceTotaldata.payloadList.filter(x => x.deviceMixPortfolio.length > 0).reduce((sum, x) => sum + x.deviceMixPortfolio[0].quantity ,0)
  const devicesWithQuantity = multiDeviceTotaldata.payloadList.filter(x => x.deviceMixPortfolio.length > 0).map(x => `${x.deviceMixPortfolio[0].quantity} ${x.deviceMixPortfolio[0].product}`).join(" ,")
  const content = {
    avgSavings: formatCurrency(data.totalSavings.savings, currencySymbol, 0),
    ROI: data.ROI % 1 === 0 ? data.ROI : data.ROI.toFixed(1),
    additionalSavings: formatCurrency(
      data.itSimplification.savings + data.employeeEmpowerment.savings,
      currencySymbol,
      0
    ),
    devicesAndOtherCosts: `${'Surface® device'}${reportData.M365LicenseCost ? ", Microsoft E365 E3 subscription" : "" }${reportData.accessoriesCosts ? ", accessories" : ""}${reportData.maintenanceCosts ? ", extended warranty, and maintenance" : ""}`,
    totalCosts: {
      data: [
        ...reportData.deviceCosts.map((x, i) => {
          return {
            value: formatCurrency(
              x?.surfaceValue ?? 0,
              currencySymbol,
              0
            ),
            label: multiDevicePerUnitdata[i].device,
            subLabel: "Surface laptop or 2-in-1",
          }
        }),
        reportData.M365LicenseCost && {
          value: formatCurrency(
            reportData.M365LicenseCost.surfaceValue,
            currencySymbol,
            0
          ),
          label: "Microsoft 365 Subscription",
          subLabel: `${formatCurrency(
            reportData.M365LicenseCost.surfaceValue / (12 * noOfYears),
            currencySymbol,
            0
          )} per user per month. License cost doesn't change across device manufacturers`,
        },
        reportData.accessoriesCosts || reportData.maintenanceCosts ? {
          value: formatCurrency(
            ((reportData.accessoriesCosts?.surfaceValue ?? 0) + (reportData.maintenanceCosts?.surfaceValue ?? 0)),
            currencySymbol,
            0
          ),
          label: `${reportData.accessoriesCosts?.surfaceValue && reportData.maintenanceCosts?.surfaceValue ? "Accessories, extended warranty & maintenance" : !reportData.accessoriesCosts?.surfaceValue && reportData.maintenanceCosts?.surfaceValue ? "Extended Warranty & Maintenance" : reportData.accessoriesCosts?.surfaceValue && !reportData.maintenanceCosts?.surfaceValue ? "Accessories" : ""}` ,
          subLabel: null,
        } : undefined,
      ],
      perDeviceTotal: formatCurrency(data.totalCosts.surfaceValue, currencySymbol, 0),
      total: {
        value: formatCurrency(data.totalCosts.surfaceValue, currencySymbol, 0),
        label: "Total Cost per Surface device",
        subLabel: `(incl. accessories) over ${noOfYears} years`,
      },
      allDeviceTotal: {
        value: formatCurrency((multiDeviceTotaldata.payloadList.filter(x => x.deviceMixPortfolio.length > 0).reduce((sum, x) => sum + (x.deviceMixPortfolio[0].msrp * x.deviceMixPortfolio[0].quantity) ,0) + ((reportData.M365LicenseCost?.surfaceValue ?? 0) * totalDeviceQuantity) + ((reportData.maintenanceCosts?.surfaceValue ?? 0) * totalDeviceQuantity) + ((reportData.accessoriesCosts?.surfaceValue ?? 0) * totalDeviceQuantity)), currencySymbol, 0),
        label: "Total cost of all devices",
        subLabel: `${devicesWithQuantity}`,
      },
    },
    totalSavings: {
      data: [
        reportData.incResidualVal && {
          value: formatCurrency(
            reportData.incResidualVal.savings,
            currencySymbol,
            0
          ),
          label: "Increased Residual Value",
          subLabel: `${getPercentage(
            reportData.incResidualVal.savings,
            reportData.incResidualVal.pcValue
          )}% higher than other PC devices`,
        },
        reportData.reducedSupport && {
          value: formatCurrency(
            reportData.reducedSupport.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Support",
          subLabel: reportData.reducedSupport.description ?? "",
        },
        reportData.accessoriesSavings && {
          value: formatCurrency(
            reportData.accessoriesSavings.savings,
            currencySymbol,
            0
          ),
          label: "Accessories Savings",
          subLabel:
            "Fewer hybrid meeting accessories (Camera, Lights, Headset)",
        },
      ],
      total: data.totalSavings.savings > 0 ? {
        value: formatCurrency(data.totalSavings.savings, currencySymbol, 0),
        label: "Total Savings per Surface device",
        subLabel: `(incl. accessories) over ${noOfYears} years`,
      } : undefined,
    },
    itSimplification: {
      data: [
        reportData.deviceConsolidation && {
          value: formatCurrency(
            reportData.deviceConsolidation.savings,
            currencySymbol,
            0
          ),
          label: "Device Consolidation",
          subLabel: reportData.deviceConsolidation.description ?? "",
        },
        reportData.reducedHDSupport && {
          value: formatCurrency(
            reportData.reducedHDSupport.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Helpdesk Support",
          subLabel: reportData.reducedHDSupport.description ?? "",
        },
        reportData.reducedOngoingMaint && {
          value: formatCurrency(
            reportData.reducedOngoingMaint.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Ongoing Maintenance",
          subLabel: reportData.reducedOngoingMaint.description ?? "",
        },
        reportData.reducedTimeForSecurityIncident && {
          value: formatCurrency(
            reportData.reducedTimeForSecurityIncident.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Time for Security Incident Response",
          subLabel: reportData.reducedTimeForSecurityIncident.description ?? "",
        },
        reportData.reducedITDeploymentCost && {
          value: formatCurrency(
            reportData.reducedITDeploymentCost.savings,
            currencySymbol,
            0
          ),
          label: "Reduced IT Deployment Costs",
          subLabel: reportData.reducedITDeploymentCost.description ?? "",
        },
        reportData.reducedTimeForSecurity && {
          value: formatCurrency(
            reportData.reducedTimeForSecurity.savings,
            currencySymbol,
            0
          ),
          label: "Reduced IT Staff Time for Ongoing Security",
          subLabel: reportData.reducedTimeForSecurity.description ?? "",
        },
        reportData.trainingCost && {
          value: formatCurrency(
            reportData.trainingCost.savings,
            currencySymbol,
            0
          ),
          label: "Higher IT Training Costs",
          subLabel: reportData.trainingCost.description ?? "",
        },
      ],
      total: data.itSimplification.savings > 0 ? {
        value: formatCurrency(data.itSimplification.savings, currencySymbol, 0),
        label: "Total IT Benefit per Surface device",
        subLabel: `(over ${noOfYears} years)`,
      } : undefined,
    },
    employeeEmpowerment: {
      data: [
        reportData.productivityGains && {
          value: formatCurrency(
            reportData.productivityGains.savings,
            currencySymbol,
            0
          ),
          label: "Productivity Gains from Increased Mobility",
          subLabel: reportData.productivityGains.description ?? "",
        },
        reportData.employeeTimeLosttoHelpdesk && {
          value: formatCurrency(
            reportData.employeeTimeLosttoHelpdesk.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Employee Time Lost to Helpdesk",
          subLabel: reportData.employeeTimeLosttoHelpdesk.description ?? "",
        },
        reportData.meetingTime && {
          value: formatCurrency(
            reportData.meetingTime.savings,
            currencySymbol,
            0
          ),
          label: "Meeting Time Saved",
          subLabel: reportData.meetingTime.description ?? "",
        },
        reportData.reducedEmpTimeLoss && {
          value: formatCurrency(
            reportData.reducedEmpTimeLoss.savings,
            currencySymbol,
            0
          ),
          label: "Reduced Employee Time Lost to Security Incidents",
          subLabel: reportData.reducedEmpTimeLoss.description ?? "",
        },
        reportData.fasterSurfDeployment && {
          value: formatCurrency(
            reportData.fasterSurfDeployment.savings,
            currencySymbol,
            0
          ),
          label: "Faster Surface Deployment to Employees",
          subLabel: reportData.fasterSurfDeployment.description ?? "",
        },
        reportData.higherEmployeeRetention && {
          value: formatCurrency(
            reportData.higherEmployeeRetention.savings,
            currencySymbol,
            0
          ),
          label: "Higher Employee Retention",
          subLabel: reportData.higherEmployeeRetention.description ?? "",
        },
        reportData.bootupTime && reportData.fasterBootupTime && {
          value: formatCurrency(
            reportData.bootupTime.savings,
            currencySymbol,
            0
          ),
          label: "Faster Bootup Time",
          subLabel: reportData.fasterBootupTime.description ?? reportData.bootupTime.description ?? "",
        },
      ],
      total: data.employeeEmpowerment.savings > 0 ? {
        value: formatCurrency(
          data.employeeEmpowerment.savings,
          currencySymbol,
          0
        ),
        label: "Total Employee Benefit per Surface device",
        subLabel: `(over ${noOfYears} years)`,
      } : undefined,
    },
    sustainability: reportData.energySavings && reportData.carbonSavings && {
      totalSavings: formatCurrency(
        data.sustainability.savings,
        currencySymbol,
        0
      ),
      energySavings: formatCurrency(
        reportData.energySavings.savings,
        currencySymbol,
        0
      ),
      carbonSavings: formatCurrency(
        reportData.carbonSavings.savings,
        currencySymbol,
        0
      ),
      isNotEmpty: data.sustainability.rows
        ? data.sustainability.rows.length > 0
        : false,
    },
    totalSavingsAndBenefits : formatCurrency(data.totalSavings.savings + data.itSimplification.savings + data.employeeEmpowerment.savings, currencySymbol, 0)
  };

  return content;
};


const getBussinessAssumptionPage = async (
  ids: string[],
  docWidth: any,
  docHeight: number,
  includeIndustry?: boolean,
  content?: any
) => {
  const isFirstPage = includeIndustry;
  const element = document.querySelector("#costSavingsBanner") as HTMLElement;
  if (!element) return;

  const canvas = await html2canvas(element, {
    backgroundColor: "white",
  });
  const bannerImageUrl = canvas.toDataURL("image/png", 1.0);
  const costSavingsBannerHTML = `
    <div class="banner-container">
      <img src="${bannerImageUrl}" style="object-fit: contain;width:100%">
    </div>
  `;
  const assumptionBlocks = ids
    .map((id) => getAssumtionBlockContent(id))
    .join("");
  const industryContent = includeIndustry ? getIndusrtyContent() : ``;
  const methodologyContent = content ? getMethodologyContent(content) : ``;

  return `
    <div style="width:${docWidth}px; height:${docHeight}px;">
      <div class="content_section one_pager" >
      ${
        isFirstPage
          ? `<div>
          <p class="main_title">The Business Case for Microsoft in Your Organization</p>
        </div> 
        ${costSavingsBannerHTML}`
          : ``
      }
        ${industryContent}
        ${assumptionBlocks}
        ${methodologyContent}
      </div>
    </div>
  `;
};

const getIndusrtyContent = () => {
  const industryData = extractIndustryData();
  return `
  <div class="industry-block">
    <div class="industry-img-section">
      <img src="${industryData?.imageUrl}" class="industry-img" alt="Industry" />
    </div>
    <div class="industry-content-section">
      <p class="sub-title">${industryData?.heading}</p>
      <div class="industry-desc-section">
        <p class="title-text">${industryData?.description}</p>
      </div>
    </div>
  </div>
  `;
};

const getAssumtionBlockContent = (id: string) => {
  const element = document.querySelector("#" + id);
  if (!element) return;
  const reportData = extractReportBlockData(element as Element);
  const successStoryData = extractSuccessStoryData(element as Element);
  return `
    <div class="block-background">
      <div class="report-section ">
        <p class="sub-title">${reportData.sectionHeading}</p>
        <div class="report-block  underline">
          <div class="left-column">
            ${reportData.headingSubHeadingPair
              .slice(0, 4)
              .map(
                (item) =>
                  `<div class="block-item">
                  <p class="title-text">${item.heading}</p>
                  <p class="desc-text">${item.subHeading}</p>
                </div>`
              )
              .join("")}  
          </div>
          <div class="right-column">
            ${reportData.headingSubHeadingPair
              .slice(4)
              .map(
                (item) =>
                  `<div class="block-item">
                  <p class="title-text">${item.heading}</p>
                  <p class="desc-text">${item.subHeading}</p>
                </div>`
              )
              .join("")}
          </div>  
        </div>
        <div>
          <p class="title-text colored-text">${reportData.footer}</p>
        </div>
      </div>
      <div class="success-story-section">
        <img src="${successStoryData.imageUrl}" alt="success story" />
        <p class="success-story-sub-title">${successStoryData.heading}</p>
        ${successStoryData.description
          .map((item) => `<p class="title-text">${item}</p>`)
          .join("")}
      </div>
    </div>
  `;
};

const getMethodologyContent = (content: any) => {
  const links = fetchLinks();
  return `
    <div class="footer-text sub-title" >
      Up to ${content.ROI}X ROI with Surface + Microsoft 365 from ${
    content.additionalSavings
  } in additional benefits per Surface device
    </div>
    <div class="methodolgy-sec-minimized">
      <p class="title">METHODOLOGY</p>
      <div class="methodology-desc-section">
        <p>
        The results above are based on customer inputs to IDC's study "Evaluating the Business Case of Microsoft Surface", commissioned by Microsoft. IDC Research Study conducted from surveys and interviews between December 2021 - February 2022. All respondents were IT decision-makers at large
          organizations (250-5000+ employees) representing organizations from the United States, Australia, India, Spain, France, United Kingdom, New Zealand,
          and Germany. Cost & Savings findings based on average cost and time estimates provided directly by respondents; actual costs and savings may vary
          based on your specific Device Mix and deployment.
        </p>
        <p>
          *Data point derived from 17 in-depth interviews. All other data points derived from 800 survey results (700 Surface organizations with at least 150 Surface
          Laptop and 2-in-1 Tablets available since 2019, 100 non-Surface organizations). Surface Residual Value adjusted by IDC to reflect average after 36 months.
        </p>
      </div>
      <div class='bubble-sec'>
          <span>
            For detailed report, <a>${BUSINESS_CASE_URL}</a>
          </span>
      </div>
      <div style="margin-top:10px">
        ${links
          ?.map((link, index) => `<p>${index + 1}. <a>${link}</a></p>`)
          .join("")}
      </div>
    </div>
  `;
};

const getTwoColTableHTML = (
  reportData: any,
  fieldName: string,
  currencySymbol: string,
  docWidth: number
) => {
  const bubbleColors = {
    itSimplification: TCO_REPORT_CHART_COLOR.it.reverse(),
    employeeEmpowerment: TCO_REPORT_CHART_COLOR.employee,
  };

  // const fieldData = reportData[fieldName as keyof TcoFinalReport] as TcoReportSegment;

  return `
    <table style="width:${docWidth - 62.5}px;font-size:9px">
    <tr>
        <th > </th>
        <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Surfaces</th>
        <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Other PCs </th>
        <th style="width:100px; border-bottom: 0.5px solid rgb(16, 124, 16);" colspan="2"> Savings </th>
    </tr>
    ${reportData[fieldName]?.rows
      ?.map(
        (item: any, i: number) => `
          <tr style="border-bottom: 1px solid rgb(237, 235, 233);">
              <td style="display:flex; align-items: center;"> 
                <div class="marker-355 marker" style="background-color: ${
                  bubbleColors[fieldName as keyof typeof bubbleColors][i]
                } ;"></div>
                ${item.label}
              </td>
              <td> ${formatCurrency(item.surfaceValue, currencySymbol, 0)} </td>
              <td> ${
                item.surfaceHours
                  ? `${formatNumberWithUnit(item.surfaceHours, null, 0)}hr`
                  : `N/A`
              } </td>
              <td> ${formatCurrency(item.pcValue, currencySymbol, 0)} </td>
              <td> ${
                item.pcHours
                  ? `${formatNumberWithUnit(item.pcHours, null, 0)}hr`
                  : `N/A`
              } </td>
              <td> ${formatCurrency(item.savings, currencySymbol, 0)} </td>
              <td> ${
                item.hoursSavings
                  ? `${formatNumberWithUnit(item.hoursSavings, null, 0)}hr`
                  : `N/A`
              } </td>
          </tr>
      `
      )
      .join("")}
    <tr>
      <td valign="middle"> 
        <div class="marker-355 marker" style="background-color: rgb(255, 255, 255);"></div>
        Total 
      </td>
      <td> ${formatCurrency(
        reportData[fieldName].surfaceValue,
        currencySymbol,
        0
      )} </td>
      <td> ${formatNumberWithUnit(
        reportData[fieldName].surfaceHours,
        null,
        0
      )}hr</td>
      <td> ${formatCurrency(
        reportData[fieldName].pcValue,
        currencySymbol,
        0
      )} </td>
      <td> ${formatNumberWithUnit(
        reportData[fieldName].pcHours,
        null,
        0
      )}hr</td>
      <td> ${formatCurrency(
        reportData[fieldName].savings,
        currencySymbol,
        0
      )} </td>
      <td> ${formatNumberWithUnit(
        reportData[fieldName].hoursSavings,
        null,
        0
      )}hr</td>
    </tr>
  </table>
    `;
};

export const generateOnePager = async (dataTotal: TcoReportPayloadList,  dataPerDeviceMulti: TcoFinalReport[], dataPerDevice: TcoFinalReport, currencySymbol: any, years: any) => {
  const doc = new jsPDF("p", "pt", "a3");

  const docWidth = doc.internal.pageSize.width;
  const docHeight = doc.internal.pageSize.height;
  const content = getPerDeviceContentMulti(dataTotal, dataPerDeviceMulti, dataPerDevice, currencySymbol, years);

  let html = `
    <div class="pdf">
        ${await getOnePagerContent(content, docWidth, docHeight, years)}
    </div>
  `; 

  if (['Kč', 'zł', '₫', '₹', '₱', '₩', '฿'].indexOf(currencySymbol) >= 0 ) {

    let customFont= amiriFont;

    if (['Kč', 'zł', '₫'].indexOf(currencySymbol) >= 0) {
      customFont = amiriFont;      
    } else if(currencySymbol === '₹') {
      customFont = rupeeFont;
    } else if(currencySymbol === '₱' || currencySymbol === '₩') {
      customFont = rayligFont;
    } 

    if (currencySymbol === '฿') {
      html = html.replaceAll(currencySymbol, 'THB')
    } else {
      doc.addFileToVFS("Custom-Regular.ttf", customFont);
      doc.addFont("Custom-Regular.ttf", "Custom", "normal");
      doc.setFont("Custom");
      html = html.replaceAll(currencySymbol, `<span style="font-family: Custom;font-weight:inherit;"> ${currencySymbol} </span>`);

    }
    
   
  }

  doc.addImage(IDCFooter, "png", 30, 1150, 339, 25);
  doc.setFontSize(10);
  doc.textWithLink('Click here to read the full study.', 30, 1145, { url: BUSINESS_CASE_URL });

  doc.html(html, {
    callback: function (doc) {  
      const docWidth = doc.internal.pageSize.width;
      const docHeight = doc.internal.pageSize.height;
      doc.setPage(1);
      // doc.addImage(logo, "png", docWidth - 195, 20, 165, 20);
      doc.link(docWidth - 68, docHeight - 40 , 25, 10, {
        url: "https://aka.ms/SurfaceIDCWhitepaper",
      });
      // doc.save("TCO_Summary.pdf");
      // doc.output('dataurlnewwindow', 'TCO_Summary.pdf');
      doc.setProperties({
        title: "TCO Summary"
      });
      window.open(doc.output('bloburl'))
    },
    autoPaging: "text",
    margin: [0, 0, 0, 0],
  });
}

const getOnePagerContent = async (content: ReturnType<typeof getPerDeviceContentMulti>, docWidth: any, docHeight: any, year: any) => {

  const costSavingBanner = async () => {
    
    var html = `<div
        style="
          display: flex;
          width:1326px;
          font-family: Segoe UI;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            background: #DEE7F6;
            padding: 30px 50px 30px 50px;
          "
        >
          <span style="font-size: 20px">Per device, up to</span>
          <h1 style="font-size: 50px; margin-top: 5px; margin-bottom: 2px; color: #001B8D">
            ${content.totalSavingsAndBenefits}
          </h1>
          <span style="font-size: 20px"
            >In total ${year}-year savings and benefits</span
          >
          <h1 style="font-size: 50px; margin-top: 5px; margin-bottom: 2px; color: #001B8D">
            ${content.ROI}X
          </h1>
          <span style="font-size: 20px">${year} year return on investment</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            background: linear-gradient(68.54deg, #E9DDD3 -10.17%, #BFC6D8 -0.23%, #F1EEF4 25.05%, #CDD5E7 82.09%, #8C9BBD 101.55%);
            flex: 1;
            padding: 10px 50px 30px 50px;
          "
        >
          <h1 style="font-size: 24px;">${year}-year costs</h1>
          <div style="display: flex; gap: 16px; align-items: center">
            <h1 style="font-size: 32px; margin-top: 5px; margin-bottom: 2px; color: #001B8D">${content.totalCosts.perDeviceTotal}</h1>
            <span style="font-size: 20px">${content.devicesAndOtherCosts}</span>
          </div>
          <h1 style="font-size: 24px;">${year}-year savings and benefits</h1>
          <div style="display: flex; justify-content: space-between">
          ${
            content.totalSavings.total?.value ? `
            <div style="display: flex; align-items: flex-start; flex-direction: column;">
              <h1 style="font-size: 32px; margin-top: 5px; margin-bottom: 2px; color: #001B8D">${content.totalSavings.total.value}</h1>
              <span style="font-size: 20px">Total direct savings</span>
            </div>`: ""
          } 
          ${
            content.itSimplification.total?.value ? `
             <div style="display: flex; align-items: ceflex-startnter; flex-direction: column; ">
              <h1 style="font-size: 32px; margin-top: 5px; margin-bottom: 2px; color: #001B8D">${content.itSimplification.total.value}</h1>
              <span style="font-size: 20px">IT efficiency benefit</span>
             </div>` : ""
          }
           ${
            content.employeeEmpowerment.total?.value ? `
              <div style="display: flex; align-items: flex-start; flex-direction: column; ">
                <h1 style="font-size: 32px; margin-top: 5px; margin-bottom: 2px; color: #001B8D">${content.employeeEmpowerment.total.value}</h1>
                <span style="font-size: 20px">Employee experience benefit</span>
              </div>
            ` : ""
           } 
          </div>
        </div>
      </div>`;
    var iframe=document.createElement('iframe');
    document.body.appendChild(iframe);

    let iframedoc= iframe.contentDocument || iframe.contentWindow?.document;

    if(iframedoc){
      iframedoc.body.innerHTML = html;
      const canvas = await html2canvas(iframedoc.body);
      const bannerImageUrl = canvas.toDataURL("image/png", 1.0);
      document.body.removeChild(iframe);
      return bannerImageUrl
    }
   
  }

  const costSavingsBannerUrl = await costSavingBanner();

  const valueDriverCards = async () => {
    const html = `<div style="display: flex; gap: 20px; width:1350px; font-family: Segoe UI;">
    <div
        style="border: 1px solid #7bd1f2;border-radius: 24px;padding: 1px;width: 300px;height: 220px;background-image: linear-gradient(47.09deg, rgb(238, 246, 255) 7.89%, rgb(251, 253, 255) 53.95%), linear-gradient(90deg, rgb(61, 203, 255) 0%, rgb(255, 255, 255) 54.5%, rgb(61, 203, 255) 100%);">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 12px; padding: 20px;">
            <img
                width="24" height="24" src=${AIpowerdicon}
                alt="why surface">
            <div style="display: flex; flex-direction: column; gap: 5px;"><span
                    style="color: rgb(0, 62, 209);  font-size: 16px; font-weight: 600; line-height: 22px;">AI-powered
                    productivity</span><span
                    style="color: rgb(0, 0, 0); line-height: 18px; font-size: 14px; font-weight: 400;">Microsoft AI
                    experiences are designed to put knowledge and expertise at your fingertips.</span></div>
        </div>
    </div>
    <div
        style="border: 1px solid #7bd1f2;border-radius: 24px;padding: 1px;width: 300px;height: 220px;background-image: linear-gradient(47.09deg, rgb(238, 246, 255) 7.89%, rgb(251, 253, 255) 53.95%), linear-gradient(90deg, rgb(61, 203, 255) 0%, rgb(255, 255, 255) 54.5%, rgb(61, 203, 255) 100%);">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 12px; padding: 20px;">
            <img
                width="24" height="24" src=${SecurityIcon}
                alt="why surface">
            <div style="display: flex; flex-direction: column; gap: 5px;"><span
                    style="color: rgb(0, 62, 209);  font-size: 16px; font-weight: 600; line-height: 22px;">Security
                    backed by Microsoft</span><span
                    style="color: rgb(0, 0, 0); line-height: 18px; font-size: 14px; font-weight: 400;">Surface delivers
                    robust security with built-in, multi-layered protection from Microsoft.</span></div>
        </div>
    </div>
    <div
        style="border: 1px solid #7bd1f2;border-radius: 24px;padding: 1px;width: 300px;height: 220px;background-image: linear-gradient(47.09deg, rgb(238, 246, 255) 7.89%, rgb(251, 253, 255) 53.95%), linear-gradient(90deg, rgb(61, 203, 255) 0%, rgb(255, 255, 255) 54.5%, rgb(61, 203, 255) 100%);">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 12px; padding: 20px;">
            <img
                width="24" height="24" src=${InstantAccessIcon}
                alt="why surface">
            <div style="display: flex; flex-direction: column; gap: 5px;"><span
                    style="color: rgb(0, 62, 209);  font-size: 16px; font-weight: 600; line-height: 22px;">Instant
                    access to the apps you need</span><span
                    style="color: rgb(0, 0, 0); line-height: 18px; font-size: 14px; font-weight: 400;">Surface is
                    optimized for instant access to Windows 11 and Microsoft 365 apps.</span></div>
        </div>
    </div>
    <div
        style="border: 1px solid #7bd1f2;border-radius: 24px;padding: 1px;width: 300px;height: 220px;background-image: linear-gradient(47.09deg, rgb(238, 246, 255) 7.89%, rgb(251, 253, 255) 53.95%), linear-gradient(90deg, rgb(61, 203, 255) 0%, rgb(255, 255, 255) 54.5%, rgb(61, 203, 255) 100%);">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 12px; padding: 20px;">
            <img
                width="24" height="24" src=${EasyToSetupIcon}
                alt="why surface">
            <div style="display: flex; flex-direction: column; gap: 5px;"><span
                    style="color: rgb(0, 62, 209);  font-size: 16px; font-weight: 600; line-height: 22px;">Easy
                    to set up and be productive</span><span
                    style="color: rgb(0, 0, 0); line-height: 18px; font-size: 14px; font-weight: 400;">Surface devices
                    are simple to set up and ready for productivity out of the box.</span></div>
        </div>
    </div>
    <div
        style="border: 1px solid #7bd1f2;border-radius: 24px;padding: 1px;width: 300px;height: 220px;background-image: linear-gradient(47.09deg, rgb(238, 246, 255) 7.89%, rgb(251, 253, 255) 53.95%), linear-gradient(90deg, rgb(61, 203, 255) 0%, rgb(255, 255, 255) 54.5%, rgb(61, 203, 255) 100%);">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 12px; padding: 20px;">
            <img
                width="24" height="24" src=${SustainabilityIcon}
                alt="why surface">
            <div style="display: flex; flex-direction: column; gap: 5px;"><span
                    style="color: rgb(0, 62, 209);  font-size: 16px; font-weight: 600; line-height: 22px;">Designed
                    with sustainability in mind</span><span
                    style="color: rgb(0, 0, 0); line-height: 18px; font-size: 14px; font-weight: 400;">Surface
                    prioritizes sustainability and accessibility in design for a positive environmental impact.</span>
            </div>
        </div>
    </div>
    <div
        style="border: 1px solid #7bd1f2;border-radius: 24px;padding: 1px;width: 300px;height: 220px;background-image: linear-gradient(47.09deg, rgb(238, 246, 255) 7.89%, rgb(251, 253, 255) 53.95%), linear-gradient(90deg, rgb(61, 203, 255) 0%, rgb(255, 255, 255) 54.5%, rgb(61, 203, 255) 100%);">
        <div style="display: flex; flex-direction: column; align-items: start; gap: 12px; padding: 20px;">
            <img
                width="24" height="24" src=${BestWayToWindowsIcon}
                alt="why surface">
            <div style="display: flex; flex-direction: column; gap: 5px;"><span
                    style="color: rgb(0, 62, 209); font-size: 16px; font-weight: 600; line-height: 22px;">The
                    best way to Windows</span><span
                    style="color: rgb(0, 0, 0); line-height: 18px; font-size: 14px; font-weight: 400;">Surface, built by
                    Microsoft, offers premier Windows 11 experiences and powerful tools.</span></div>
        </div>
    </div>
</div>`

    var iframe=document.createElement('iframe');
    document.body.appendChild(iframe);

    let iframedoc= iframe.contentDocument || iframe.contentWindow?.document;

    if(iframedoc){
      iframedoc.body.innerHTML = html;
      const canvas = await html2canvas(iframedoc.body);
      const cardsImageUrl = canvas.toDataURL("image/png", 1.0);
      document.body.removeChild(iframe);
      return cardsImageUrl
    }
  }

  const valueDriverCardsUrl = await valueDriverCards();

  return `
  <div style="width:${docWidth}px; height:${docHeight}px; font-family: sans-serif">
    <div style="padding-top: 20px" class="content_section one_pager">
      <div style="display: flex; justify-content: space-between;">
        <div style="display: flex; flex-direction: column">
          <h1 style="font-size: 14px; margin-top: 0; margin-bottom: 7px">The Business Case for Microsoft in Your Organization</h1>
          
        </div>
        <img width=120px height=15px src="${logo}">
      </div>
      <div>
          <p style="margin: 20px 0px 20px 0px; font-size: 12px; font-weight:600" align="center">Designed by Microsoft. Built for Business. Inspired by the way you work.</p>
          <img src="${valueDriverCardsUrl}" style="object-fit: contain;width:100%;"/>
          <p style="font-size: 10px;margin: 10px 0px 10px 0px;">What does the Surface TCO look like</p>
          <img src="${costSavingsBannerUrl}" style="object-fit: contain;width:100%;"/>
          <p style="font-size: 10px; margin-top: 7px; margin-bottom: 9px">Overall, IDC's research shows Surface benefits are worth almost three times the investment costs over ${year} years due to the following:</p>
          <div class="d-flex p-table">
              ${getPriceTable(content, 'totalCosts')}
              ${getPriceTable(content, 'totalSavings')}
          </div>
      </div>
      <div style="margin-top:10px">
          <div class="d-flex  p-table">
              ${getPriceTable(content, 'itSimplification')}
              ${getPriceTable(content, 'employeeEmpowerment')}
          </div>
      </div>
    </div>
    
  </div>
`;
}

const getPriceTable = (
  content: ReturnType<typeof getPerDeviceContentMulti>,
  section: keyof Omit<ReturnType<typeof getPerDeviceContentMulti>, "avgSavings" | "ROI" | "additionalSavings" | "devicesAndOtherCosts" | "sustainability" | "totalSavingsAndBenefits">,
) => {

 
  const title: Record<string, string> = {
    'totalCosts' : 'Costs',
    'totalSavings': 'Savings',
    'itSimplification': 'IT Efficiency',
    'employeeEmpowerment': 'Employee Experience'
  };
  

  return `
  ${
    content[section].data?.filter(x => x).length > 0 ? `
    <div style="display: flex; flex-direction: column">
        <div style="border-bottom: 2px solid"><h1 style="font-size: 11px">${title[section]}</h1></div>
        <div class="price-lines">
        ${
          content[section].data.map((item) => item && `
            <div class="price-line">
              <div class="price-line-title">
                <h2 style="font-size: 12px;">${item.value}</h2>
              </div>
              <div  class="price-line-description" style="display: flex; flex-direction: column">
                <span style="font-weight:bold;font-size: 10px">${item.label}</span>
                ${item.subLabel ? ` <span style="font-size: 8px; font-weight: lighter">
                  ${item.subLabel}
                </span>` : ``} 
              </div>
            </div>
          `).join("")
        }
        ${
          content[section].total && `
          <div class="price-line total">
            <div class="price-line-title" >
              <h2 style="font-size: 12px;">${content[section].total?.value}</h2>
            </div>
            <div class="price-line-description" style="display: flex; flex-direction: column">
              <span style="font-weight:bold;font-size: 10px">${content[section].total?.label}</span>
              ${content[section].total?.subLabel ? ` <span style="font-size: 8px; font-weight: lighter">
                ${content[section].total?.subLabel}
              </span>` : ``} 
            </div>
         </div>`
        }
         ${
          "allDeviceTotal" in content[section] ? `
          <div class="price-line total-all">
            <div class="price-line-title">
              <h2 style="font-size: 12px;">${(content[section] as any).allDeviceTotal.value}</h2>
            </div>
            <div class="price-line-description" style="display: flex; flex-direction: column">
              <span style="font-weight:bold;font-size: 10px">${(content[section] as any).allDeviceTotal.label}</span>
              ${(content[section] as any).allDeviceTotal.subLabel ? ` <span style="font-size: 8px; font-weight: lighter">
                ${(content[section] as any).allDeviceTotal.subLabel}
              </span>` : ``} 
            </div>
          </div>`: ``
        }
        </div>
      </div>` : ""
  }
  `;
}