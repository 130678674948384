import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { TcoReportPayload, TcoReportPayloadList } from "v2/types/apiPayloadTypes";
import {
  TcoAssumption,
  TcoConfig,
  TcoFinalReport,
  TcoFinalReportMultipleDevices,
  TcoReport,
} from "v2/types/apiResponseTypes";
import { CodeNamePair, discountRate } from "v2/types/commonTypes";
import { Currency } from "v2/types/currencyType";
import { CustomerDetail } from "v2/types/customerDetailType";
import { ProductDetails } from "v2/types/productTypes";

export interface ITcoCalculationContext {
  tcoConfig: TcoConfig;
  selectedCountry: CodeNamePair;
  setSelectedCountry: Dispatch<SetStateAction<CodeNamePair>>;
  customerInfo: CustomerDetail;
  setCustomerInfo: Dispatch<SetStateAction<CustomerDetail>>;
  selectedDevices: ProductDetails[];
  setSelectedDevices: Dispatch<SetStateAction<ProductDetails[]>>;
  tcoReport: TcoReport;
  tcoCompleteReport: TcoFinalReport;
  setTcoReport: Dispatch<SetStateAction<TcoReport>>;
  selectedYears: number;
  setSelectedYears: Dispatch<SetStateAction<number>>;
  tcoReportPayload: TcoReportPayload;
  setTcoCompleteReport: Dispatch<SetStateAction<TcoFinalReport>>;
  setTcoReportPayload: Dispatch<SetStateAction<TcoReportPayload>>;
  tcoAssumptions: TcoAssumption[];
  discountRate: discountRate;
  setDisCountRate: Dispatch<SetStateAction<discountRate>>;
  currency: Currency;
  tcoReportPayloadPerDevice: TcoReportPayloadList;
  setTcoReportPayloadPerDevice: Dispatch<SetStateAction<TcoReportPayloadList>>;
  tcoCompleteReportPerDevice: TcoFinalReport;
  setTcoCompleteReportPerDevice: Dispatch<SetStateAction<TcoFinalReport>>;

  tcoReportPayloadList: TcoReportPayloadList;
  setTcoReportPayloadList: Dispatch<SetStateAction<TcoReportPayloadList>>;

  tcoCompleteReportMultipleDevice: TcoFinalReportMultipleDevices;
  setTcoCompleteReportMultipleDevice: Dispatch<SetStateAction<TcoFinalReportMultipleDevices>>;

  customerInfoSubmit: boolean,
  setCustomerInfoSubmit: Dispatch<SetStateAction<boolean>>;
}

export const TcoCalculationContext = createContext<ITcoCalculationContext>(
  {} as ITcoCalculationContext
);

export const useTcoCalculationContext = () => useContext(TcoCalculationContext);
