import { Stack, StackItem } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import Header, { IHeaderProps } from "v2/components/common/Header";
import CustomerInfoForm from "v2/components/customer-form/CustomerInfoForm";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { CustomerDetail } from "v2/types/customerDetailType";

const headerDetails: IHeaderProps = {
  title: "Tell us about yourself",
  subtitle:
    "Please complete the following information below in order for us to better assist you.",
  step: "STEP 1",
};

const CustomerInfo: FC = () => {
  const { customerInfo, setCustomerInfoSubmit } = useTcoCalculationContext();
  const navigate = useNavigate();
  const [initialCustomerInfo, setInitialCustomerInfo] = useState<CustomerDetail>(customerInfo);

  useEffect(() => {
    setInitialCustomerInfo(customerInfo);
    return () => {
      customerInfoSubmit();
    }
  }, [])

  useEffect(() => {
    customerInfoSubmit();
  }, [customerInfo]);

  const customerInfoSubmit = () => {
    if(validateCustomerInfo()){
      setCustomerInfoSubmit(true);
      setInitialCustomerInfo(customerInfo);
    }
  }

  const validateCustomerInfo = (): boolean => {
    if(!customerInfo.companySize){
      return false;
    }
    if(!customerInfo.industry){
      return false;
    }
    if(!customerInfo.role){
      return false;
    }
    return customerInfo.companySize !== initialCustomerInfo.companySize || 
    customerInfo.industry !== initialCustomerInfo.industry ||
     customerInfo.role !== initialCustomerInfo.role || 
     customerInfo.companyName !== initialCustomerInfo.companyName
  }

  const isButtonDisabled = (): boolean => {
    return !(
      customerInfo.industry &&
      customerInfo.role &&
      customerInfo.companySize
    );
  };

  return (
    <Stack
      styles={{ root: { padding: "40px", width: "90%" } }}
      tokens={{ childrenGap: 32 }}
    >
      <Header
        title={headerDetails.title}
        subtitle={headerDetails.subtitle}
        step={headerDetails.step}
      />
      <StackItem>
        <CustomerInfoForm />
      </StackItem>
      <StackItem align="start">
        <Button
          appearance="primary"
          disabled={isButtonDisabled()}
          onClick={() => navigate("/choose-your-surface-mix")}
        >
          Continue
        </Button>
      </StackItem>
    </Stack>
  );
};

export default CustomerInfo;
