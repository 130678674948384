import React, {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ProductDetails } from "../../types/productTypes";
import {
  Card,
  Dropdown,
  Input,
  Label,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  Text,
  ToggleButton,
} from "@fluentui/react-components";
import { useId } from "@fluentui/react-hooks";
import {
  Delete32Regular,
  Edit16Regular,
  Edit32Regular,
  SubtractCircle32Regular,
} from "@fluentui/react-icons";
import { style } from "./ProductDetailsStyle";
import { Stack } from "@fluentui/react";
import { CodeNamePair } from "v2/types/commonTypes";
import defaultDevices from "v2/store/DefaultDevices.json";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
} from "v2/utilities/currencyConverter";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { formatNumber } from "v2/utilities/helper";
const ShapeImage = "Shape.svg";
type DropdownsState = {
  processorList: CodeNamePair[];
  ramList: CodeNamePair[];
  driveSizeList: CodeNamePair[];
};
const emptyDevice: ProductDetails = {
  code: "",
  name: "",
  description: "",
  ram: "",
  pfam: "",
  sku: "",
  msrp: 0,
  driveSize: "",
  processor: "",
  subscription: "",
};
const ProductDetailsTile: React.FC<{
  product: ProductDetails;
  image: string;
  index: number;
}> = (props) => {
  const [productsMasterDataState, setProductsMasterDataState] = useState<
    ProductDetails[]
  >([]);
  const [quantityError, setQuantityError] = useState<boolean>(false);
  const [msrpError, setMsrpError] = useState<boolean>(false);
  const [customizeProductDetails, setCustomizeProductDetails] =
    useState<boolean>(false);
  const [dropdowns, setDropdowns] = useState<DropdownsState>({
    processorList: [],
    ramList: [],
    driveSizeList: [],
  });
  const {
    tcoConfig,
    selectedCountry,
    selectedDevices,
    setSelectedDevices,
    currency,
  } = useTcoCalculationContext();
  const [editMsrp, setEditMsrp] = useState<boolean>(false);
  const [convertedMsrp, setConvertedMsrp] = useState<number>();

  const getSubscriptionName = useCallback(
    (code?: string) => {
      const subscription = tcoConfig?.m365Subscriptions.find(
        (sub) => sub.code === code
      );
      return subscription
        ? subscription.name
        : tcoConfig?.m365Subscriptions[0]?.name;
    },
    [tcoConfig?.m365Subscriptions]
  );
  const setDropdownLists = useCallback(() => {
    const driveSize = Array.from(
      new Set(
        productsMasterDataState.map((item: ProductDetails) => item.driveSize)
      )
    ).map((name: string) => ({ code: name, name: name }));
    const processorList = Array.from(
      new Set(
        productsMasterDataState
          .filter(
            (item: ProductDetails) => item.driveSize === props.product.driveSize
          )
          .map((item: ProductDetails) => item.processor)
      )
    ).map((name: string) => ({ code: name, name: name }));
    const ramList = Array.from(
      new Set(
        productsMasterDataState
          .filter(
            (item: ProductDetails) =>
              item.driveSize === props.product.driveSize &&
              item.processor === props.product.processor
          )
          .map((item: ProductDetails) => item.ram)
      )
    ).map((name: string) => ({ code: name, name: name }));
    setDropdowns((prevState) => ({
      ...prevState,
      processorList: processorList,
      ramList: ramList,
      driveSizeList: driveSize,
    }));
  }, [
    productsMasterDataState,
    props.product.driveSize,
    props.product.processor,
  ]);

  const currencyObj = useMemo(() => {
    return tcoConfig?.currencies?.find(
      (x) => x.countryCode === selectedCountry.code
    );
  }, [selectedCountry.code, tcoConfig?.currencies]);
  useEffect(() => {
    setDropdownLists();
  }, [setDropdownLists]);

  useEffect(() => {
    setProductsMasterDataState(
      tcoConfig?.newProducts?.filter(
        (product) => product.name === props.product.name
      ) || []
    );
  }, [props.product.name, tcoConfig?.newProducts]);

  useEffect(() => {
    if (props.product) {
      let msrpInUSD = props.product.msrp;
      if (selectedCountry?.code !== "US") {
        msrpInUSD = convertFromUSDToOther(msrpInUSD, currencyObj);
      }
      setConvertedMsrp(msrpInUSD);
    }
  }, [currencyObj, props.product, selectedCountry?.code]);
  const baseId = useId();
  const QUANTITY_LIMIT = 99999;
  const numberOfAactiveDevices = selectedDevices.filter(
    (device: ProductDetails) => device.code !== ""
  ).length;
  const removeEnabled = props.product.name === "" ? true : false;

  const selectedDeviceNames = selectedDevices
    .filter((device) => device.name !== props.product.name)
    .map((device) => device.name);
  const deviceOptions = useMemo(() => {
    return Array.from(
      new Set(tcoConfig?.newProducts?.map((device) => device.name))
    )
      .map((name) => ({ key: name, text: name }))
      .filter((option) => !selectedDeviceNames.includes(option.text));
  }, [tcoConfig?.newProducts, selectedDeviceNames]);

  const customizeProductHandler = () => {
    setCustomizeProductDetails(!customizeProductDetails);
  };

  const removeButtonHandler = (event: MouseEvent<HTMLButtonElement>) => {
    const updatedDevices = selectedDevices.map((selectedDevice) =>
      selectedDevice.code === props.product.code ? emptyDevice : selectedDevice
    );
    setSelectedDevices(updatedDevices);
    setCustomizeProductDetails(false);
    setQuantityError(false);
  };

  const onQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value.replaceAll(",", ""));
    if (value > QUANTITY_LIMIT) return;
    setQuantityError(isNaN(value) || value === 0);
    const curDevice = {
      ...props.product,
      quantity: value,
    };
    setSelectedDevices((prevState) =>
      prevState.map((device, i) => (i === props.index ? curDevice : device))
    );
  };

  const onDeviceChange = (event: SelectionEvents, data: OptionOnSelectData) => {
    const productsMasterData = tcoConfig.newProducts
      ?.filter((product) => product.name === data.optionText)
      .map((product) => ({ ...product }));
    const description = defaultDevices.defaultDescriptions.find(
      (item: any) => item.name === data.optionText
    )?.description;
  
    const defaultProduct = productsMasterData.find(i => i.defaultDisplay)||productsMasterData[0]; 
        
    if (defaultProduct) {
      defaultProduct.description = description; 
      defaultProduct.subscription = tcoConfig?.m365Subscriptions[0].code;
      defaultProduct.quantity = props.product?.quantity ?? undefined;
      setSelectedDevices((prevState) =>
        prevState.map((device, i) =>
          i === props.index ? defaultProduct : device
        )
      );
    }
    // setDropdownLists();
  };

  const onStorageDriveChange = (
    event: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    const deviceDetails = productsMasterDataState
      ?.filter(
        (product: ProductDetails) =>
          product.name === props.product.name &&
          product.driveSize === data.optionText
      )
      .map((product) => ({ ...product }));
    deviceDetails[0].quantity = props.product?.quantity ?? undefined;
    deviceDetails[0].subscription =
      props.product?.subscription ?? tcoConfig?.m365Subscriptions[0].code;
    const description = defaultDevices.defaultDescriptions.find(
      (item: any) => item.name === deviceDetails[0].name
    )?.description;
    deviceDetails[0].description = description;
    setSelectedDevices((prevState) =>
      prevState.map((device, i) =>
        i === props.index ? deviceDetails[0] : device
      )
    );
    setDropdownLists();
  };

  const onProcessorChange = (
    event: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    const deviceDetails = productsMasterDataState
      ?.filter(
        (item: ProductDetails) =>
          item.name === props.product.name &&
          item.driveSize === props.product.driveSize &&
          item.processor === data.optionText
      )
      .map((product) => ({ ...product }));
    deviceDetails[0].quantity = props.product?.quantity ?? undefined;
    deviceDetails[0].subscription =
      props.product?.subscription ?? tcoConfig?.m365Subscriptions[0].code;
    const description = defaultDevices.defaultDescriptions.find(
      (item: any) => item.name === deviceDetails[0].name
    )?.description;
    deviceDetails[0].description = description;
    setSelectedDevices((prevState) =>
      prevState.map((device, i) =>
        i === props.index ? deviceDetails[0] : device
      )
    );
    setDropdownLists();
  };
  const onRamChange = (event: SelectionEvents, data: OptionOnSelectData) => {
    const deviceDetails = productsMasterDataState
      ?.filter(
        (item: ProductDetails) =>
          item.name === props.product.name &&
          item.driveSize === props.product.driveSize &&
          item.processor === props.product.processor &&
          item.ram === data.optionText
      )
      .map((product) => ({ ...product }));
    deviceDetails[0].quantity = props.product?.quantity ?? undefined;
    deviceDetails[0].subscription =
      props.product?.subscription ?? tcoConfig?.m365Subscriptions[0].code;
    const description = defaultDevices.defaultDescriptions.find(
      (item: any) => item.name === deviceDetails[0].name
    )?.description;
    deviceDetails[0].description = description;
    setSelectedDevices((prevState) =>
      prevState.map((device, i) =>
        i === props.index ? deviceDetails[0] : device
      )
    );
    setDropdownLists();
  };

  const editMsrpHandler = () => {
    setEditMsrp(true);
  };

  const onMsrpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let msrpValue = parseFloat(event.target.value);
    setMsrpError(isNaN(msrpValue) || msrpValue === 0);
    if (selectedCountry.code !== "US")
      msrpValue = convertFromOtherToUSD(msrpValue, currencyObj);
    const curDevice = { ...props.product, msrp: msrpValue };
    setSelectedDevices((prevState) =>
      prevState.map((device, i) => (i === props.index ? curDevice : device))
    );
  };

  const onSubscriptionChange = (
    event: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    const curDevice = {
      ...props.product,
      subscription: data.optionValue,
    };
    setSelectedDevices((prevState) =>
      prevState.map((device, i) => (i === props.index ? curDevice : device))
    );
  };
  return (
    <Card className={style.productCardContainer}>
      <Stack className={style.mainContentWrapper}>
        <Stack className={style.productCardHeader}>
          {removeEnabled ? (
            <>
              <img
                className={style.productImage}
                src={require(`../../assets/tco/${ShapeImage}`)}
                alt={ShapeImage}
              />
              <p className={style.noDeviceHeaderFont}>Add a device</p>
              <p
                className={`${style.productDescriptionFont} ${style.tcoNormalFont}`}
              >
                Select a device to add to your mix and specify the desired
                quantity.
              </p>
            </>
          ) : (
            <>
              <img
                className={style.productImage}
                src={require(`../../assets/tco/${props.image}`)}
                alt={props.image}
              />
              <p className={style.productHeaderFont}>{props.product.name}</p>
              <p
                className={`${style.productDescriptionFont} ${style.tcoNormalFont}`}
              >
                {props.product.description}
              </p>
            </>
          )}
        </Stack>
        <Stack className={style.dropdownWrapper}>
          <Label required>Device</Label>
          <Dropdown
            id={`${baseId}-product-default`}
            placeholder="Select a device"
            value={props.product.name}
            onOptionSelect={onDeviceChange}
          >
            {deviceOptions.map((option) => (
              <Option
                checkIcon={null}
                key={option.key}
                value={option.key}
                text={option.text}
              >
                {option.text}
              </Option>
            ))}
          </Dropdown>
        </Stack>
        <Stack className={style.productCardBody}>
          <Stack className={style.inputWrapper}>
            <Label required>Quantity</Label>
            <Input
              id={`${baseId}-quantity-default`}
              required
              value={formatNumber(`${props.product.quantity}`)}
              max={QUANTITY_LIMIT}
              onBlur={onQuantityChange}
              onChange={onQuantityChange}
              disabled={removeEnabled}
              className={quantityError ? style.errorInput : ""}
              placeholder="Enter quantity"
            />
            {quantityError && (
              <span className={style.errorText}>Quantity is required</span>
            )}
          </Stack>
        </Stack>
        {customizeProductDetails && (
          <>
            <Stack
              className={style.productConfigurationRow}
              horizontal
              horizontalAlign="space-between"
            >
              <Stack className={style.dropdownWrapper}>
                <Label required>Storage drive</Label>
                <Dropdown
                  id={`${baseId}-storage-default`}
                  value={props.product.driveSize}
                  disabled={removeEnabled}
                  onOptionSelect={onStorageDriveChange}
                >
                  {dropdowns.driveSizeList?.map((option) => (
                    <Option
                      checkIcon={null}
                      key={option.code}
                      value={option.code}
                      text={option.name}
                    >
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </Stack>
              <Stack className={style.dropdownWrapper}>
                <Label required>Microsoft subscription</Label>
                <Dropdown
                  id={`${baseId}-subscription-default`}
                  value={getSubscriptionName(props.product.subscription)}
                  disabled={removeEnabled}
                  onOptionSelect={onSubscriptionChange}
                >
                  {tcoConfig?.m365Subscriptions.map((option) => (
                    <Option
                      checkIcon={null}
                      key={option.code}
                      value={option.code}
                      text={option.name}
                    >
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </Stack>
            </Stack>
            <Stack
              className={style.productConfigurationRow}
              horizontal
              horizontalAlign="space-between"
            >
              <Stack className={style.dropdownWrapper}>
                <Label required>Processor</Label>
                <Dropdown
                  id={`${baseId}-processor-default`}
                  value={props.product.processor}
                  disabled={removeEnabled}
                  onOptionSelect={onProcessorChange}
                >
                  {dropdowns.processorList?.map((option) => (
                    <Option
                      checkIcon={null}
                      key={option.code}
                      value={option.code}
                      text={option.name}
                    >
                      {option.code}
                    </Option>
                  ))}
                </Dropdown>
              </Stack>
              <Stack className={style.dropdownWrapper}>
                <Label required htmlFor={`${baseId}-ram-default`}>
                  RAM
                </Label>
                <Dropdown
                  id={`${baseId}-ram-default`}
                  value={props.product.ram}
                  disabled={removeEnabled}
                  onOptionSelect={onRamChange}
                >
                  {dropdowns.ramList?.map((option) => (
                    <Option
                      checkIcon={null}
                      key={option.code}
                      value={option.code}
                      text={option.name}
                    >
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </Stack>
            </Stack>
            <Stack className={style.inputWrapper}>
              <Label required>
                Approximate MSRP
                <ToggleButton
                  onClick={editMsrpHandler}
                  appearance="transparent"
                  disabled={removeEnabled}
                  icon={<Edit16Regular />}
                ></ToggleButton>
              </Label>
              <Input
                id={`${baseId}-msrp-default`}
                type="number"
                value={`${convertedMsrp}`}
                onChange={onMsrpChange}
                required
                disabled={!editMsrp || removeEnabled}
                step="0.01"
                className={msrpError ? style.errorInput : ""}
                contentBefore={
                  <Text size={300} id={`${baseId}-msrp-currency-before`}>
                    {currency?.symbol}
                  </Text>
                }
              />
              {msrpError && (
                <span className={style.errorText}>MSRP is required</span>
              )}
            </Stack>
          </>
        )}
      </Stack>
      {!removeEnabled ? (
        <Stack
          className={style.productConfigurationRow}
          horizontal
          horizontalAlign="space-between"
        >
          {customizeProductDetails ? (
            <ToggleButton
              onClick={customizeProductHandler}
              className={style.customizeButton}
              appearance="transparent"
              icon={<SubtractCircle32Regular />}
            >
              {" "}
              Minimize product details
            </ToggleButton>
          ) : (
            <ToggleButton
              onClick={customizeProductHandler}
              className={style.customizeButton}
              appearance="transparent"
              icon={<Edit32Regular />}
            >
              {" "}
              Customize product details
            </ToggleButton>
          )}
          {numberOfAactiveDevices > 1 && (
            <ToggleButton
              onClick={removeButtonHandler}
              appearance="transparent"
              className={style.deleteButton}
              icon={<Delete32Regular />}
            >
              Remove
            </ToggleButton>
          )}
        </Stack>
      ) : (
        <Stack
          className={style.productConfigurationRow}
          verticalFill
          verticalAlign="space-between"
        >
          <Stack></Stack>
          <Stack></Stack>
        </Stack>
      )}
    </Card>
  );
};

export default memo(ProductDetailsTile);
