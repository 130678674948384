import { mergeStyleSets, Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { InfoLabel, InputOnChangeData, Switch } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput, { Tooltip } from "../common/LabelledInput";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
} from "v2/utilities/currencyConverter";
import { TcoReportRow } from "v2/types/apiResponseTypes";
import {
  convertNumberToString,
  convertStringToNumber,
  formatNumber,
} from "utils/helpers";
import { AdvancedInput, AdvancedInputItem } from "v2/types/advancedInput";

const styles = mergeStyleSets({
  switchIndicator : {
      "& .fui-Switch__indicator":{
        marginLeft: "0px",
        marginTop: "0px"
      }
    }
});
interface EmployeeExperienceProps {
  device: string; // Add your desired prop here
}

let activeInputFieldKey: string | null = null;

const EmployeeExperience: FC<EmployeeExperienceProps> = ({device}) => {
  const {
    currency,
    tcoCompleteReport,
    setTcoReportPayload,
    selectedCountry,
    tcoReportPayload,
    tcoReportPayloadList,setTcoReportPayloadList,
    tcoCompleteReportMultipleDevice,
  } = useTcoCalculationContext();

  const [employeeExperience, setEmployeeExperience] = useState<TcoReportRow>(
    {} as TcoReportRow
  );

  const getInfo = (field: string) => {
    return <Stack>{field}</Stack>;
  };
  const EmployeeExperienceData = [
    {
      key: "Reduced Employee Time Lost to Helpdesk",
      label: "Reduced Employee Time Lost to Helpdesk",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Microsoft Surface devices reduced the time employees spent using HelpDesk, with 7.2 hours spent in comparison to 12.7 for other devices—a difference of 5.5 hours. This resulted in a three-year benefit of $619 and an efficiency improvement of 43 percent. (Refer to IDC study.)"
      ),
    },
    {
      key: "Meeting Time Saved",
      label: "Meeting Time",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Microsoft Surface devices used 110 hours of meeting time annually in comparison to 118 for other devices—a difference of 8 hours. This resulted in a three-year benefit of $864 and a seven-percent improvement in efficiency. (Refer to IDC study.)"
      ),
    },
    {
      key: "Faster Bootup Time",
      label: "Faster Bootup Time",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Microsoft Surface devices had faster startup times when compared to other devices, with an annual difference of 0.7 hours. Other devices took 1.9 hours to start, while Microsoft Surface devices only took 1.2 hours. This resulted in a three-year benefit of $81 and a 37-percent improvement in efficiency. (Refer to IDC study.) (Number of reboots per year, per device) * (Time per reboot (minutes), per device) / 60"
      ),
    },
    {
      key: "Reduced Employee Time Lost to Security Incidents",
      label: "Reduced Employee Time Lost to Security Incidents",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' While employees using other devices lost 2.8 hours annually to security incidents, Microsoft Surface devices reduced this time to 2 hours. This resulted in saving 0.8 hours and a gaining a three-year benefit of $89, with a 29-percent improvement in efficiency. (Refer to IDC study.)"
      ),
    },
    {
      key: "Faster Surface Deployment to Employees",
      label: "Faster Surface Deployment to Employees",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Results demonstrated that it was faster to deploy Microsoft Surface devices to employees in comparison to deploying other devices, with a one-time difference of 1.6 hours. Other devices took 6.1 hours to deploy, while Microsoft Surface devices took 4.5 hours. This resulted in a three-year benefit of $60 and a 26-percent improvement in efficiency. (Refer to IDC study.)"
      ),
    },
    {
      key: "Higher Employee Retention",
      label: "Higher Employee Retention",
      isAmount: false,
      DisplayProperty: "savings",
      info: getInfo(
        "The total benefit of higher employee retention for a Surface device over three years is $944, which is $105 more compared to other devices. This results in a 4% increase in employee retention."
      ),
      noInput: true
    },
    {
      key: "Productivity Gains from Increased Mobility",
      label: "Productivity Gains from Increased Mobility",
      isAmount: false,
      DisplayProperty: "savings",
      info: getInfo(
        "Microsoft Surface devices, with their lightweight design and LTE models, provide greater mobility benefits, with an annual productivity increase of 11.6 more hours when compared to other devices. This led to a $1,290 three-year benefit. (Refer to IDC study.)"
      ),
      noInput: true
    },
  ];

  const onChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    tcoreport: any,
    key: string,
    displayProperty: string
  ) => {
    activeInputFieldKey = key
    const copiedTcoReportPayloadList = buildTcoReportPayloadList(ev.target.value, key, displayProperty, tcoreport, true);
    setTcoReportPayloadList(copiedTcoReportPayloadList);

    const tcoreportCopy = { ...tcoreport };
    tcoreportCopy[key][displayProperty] = formatNumber(data.value===""?"0":data.value);
    setEmployeeExperience(tcoreportCopy);

  };

  const buildTcoReportPayloadList = (value: string,
    key: string,
    displayProperty: string,
    tcoreport: any,
    checked: boolean) => {
    const tcoreportCopy = { ...tcoreport };

    let data =
      displayProperty === "pcValue"
        ? selectedCountry.code === "US"
          ? value.toString()
          : convertFromOtherToUSD(
              convertStringToNumber(value),
              currency
            ).toString()
        : value;

    tcoreportCopy[key][displayProperty] = formatNumber(data);

    // const advancedinput: AdvancedInput = {};
    // const advancedpayload =
    //   tcoReportPayload.advancedInput === undefined
    //     ? advancedinput
    //     : tcoReportPayload.advancedInput;
    // tcoReportPayload.advancedInput = advancedpayload;
    
    const advancedinput: AdvancedInput = {};
    
    let advancedpayload =
      
    tcoReportPayloadList.payloadList?.find(i=>i.deviceMixPortfolio[0].product==device)?.advancedInput === undefined
       ? advancedinput
       :  tcoReportPayloadList.payloadList?.find(i=>i.deviceMixPortfolio[0].product==device)?.advancedInput;

      var item= tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product==device);

      if(item)
      {
       item.advancedInput = advancedpayload;
      }   

    const isDataInvalid = (data === "0" || data === undefined || data === null);

    if (key == "Reduced Employee Time Lost to Helpdesk") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: isDataInvalid && checked === true ? undefined : formatNumber(data),
        isHoursChanged: isDataInvalid && checked === true ? false : true,
      };
      if (advancedpayload) {
      advancedpayload.employeeTimeLosttoHelpdesk = advancedAssumptionsInputItem;
      }
    }

    if (key == "Meeting Time Saved") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcHours: isDataInvalid ? undefined : formatNumber(data),
        isHoursChanged: isDataInvalid && checked === true ? false : true,
      };
      if (advancedpayload) {
      advancedpayload.meetingTime = advancedAssumptionsInputItem;
      }
    }

    if (key == "Reduced Employee Time Lost to Security Incidents") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: isDataInvalid && checked === true ? undefined : formatNumber(data),
        isHoursChanged: isDataInvalid && checked === true ? false : true,
      };

      if(advancedpayload)
      {
        advancedpayload.employeeTimeLostToSecurityIncidents =
        advancedAssumptionsInputItem;
       }
    }

    if (key == "Faster Surface Deployment to Employees") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: isDataInvalid && checked === true ? undefined : formatNumber(data),
        isHoursChanged: isDataInvalid && checked === true ? false : true,
      };
      if(advancedpayload)
      {
      advancedpayload.surfaceDeployment = advancedAssumptionsInputItem;
      }
    }

    if (key == "Faster Bootup Time") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: isDataInvalid && checked === true ? undefined : formatNumber(data),
        isHoursChanged: isDataInvalid && checked === true ? false : true,
      };
      if(advancedpayload)
      {
      advancedpayload.bootupTime = advancedAssumptionsInputItem;
      }
    }

    if (key === "Higher Employee Retention") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
      };
      if(advancedpayload)
      {
      advancedpayload.employeeRetention = advancedAssumptionsInputItem;
      }
    }

    if (key === "Productivity Gains from Increased Mobility") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked,
      };
      if(advancedpayload)
      {
      advancedpayload.productivityGains = advancedAssumptionsInputItem;
      }
    }

    // let tcoReportPayloadCopy = {
    //   ...tcoReportPayload,
    //   advancedInput: advancedpayload,
    // };

    // setTcoReportPayload(tcoReportPayloadCopy);

    let tcoReportPayloadCopy = {
      ... tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product==device),
      advancedInput: advancedpayload,
    };

    let copiedTcoReportPayloadList = { ...tcoReportPayloadList };

    let y = copiedTcoReportPayloadList.payloadList.find(i => i.deviceMixPortfolio[0].product == device);
    
    if (y) {
      y.advancedInput = tcoReportPayloadCopy.advancedInput;
    }

    return copiedTcoReportPayloadList;
  }

  // useEffect(() => {
  //   const data = EmployeeExperienceData.reduce((acc, item) => {
  //     let rowItem = tcoCompleteReport.employeeEmpowerment?.rows?.filter(
  //       (row) => row.label === item.key
  //     )[0] as any;
  //     if (!rowItem) {
  //       return acc;
  //     }
      
  //     rowItem.pcHours= convertNumberToString(Number(convertStringToNumber(rowItem.pcHours))).toString();
  //     rowItem.pcValue =
  //       selectedCountry.code != "US" && item.DisplayProperty != "pcHours"
  //         ? convertFromUSDToOther(rowItem.pcValue, currency)
  //         : rowItem.pcValue;

  //     return {
  //       ...acc,
  //       [item.key]: rowItem,
  //     };
  //   }, {} as any);

  //   setEmployeeExperience((preState) => {
  //     const updatedState = data;
  //     return updatedState;
  //   });
  // }, [currency, tcoCompleteReport]);

  
  useEffect(() => {
    const data = EmployeeExperienceData.reduce((acc, item) => {
      let rowItem = tcoCompleteReportMultipleDevice.deviceResponse.find(i=>i.device===device)?.employeeEmpowerment?.rows?.filter(
        (row) => row.label === item.key
      )[0] as any;
      if (!rowItem) {
        return {
          ...acc,
          [item.key]: {
            pcHours: (employeeExperience as any)?.[item.key]?.pcHours ?? "0",
            pcValue: (employeeExperience as any)?.[item.key]?.pcValue ?? "0",
            savings: (employeeExperience as any)?.[item.key]?.savings.toString() ?? "0",
            checked: false
          }
          
        };
      }
      const cloneRowItem = {...rowItem};
      cloneRowItem.pcHours= (rowItem?.pcHours != undefined) ? activeInputFieldKey === rowItem.label ? (employeeExperience as any)?.[item.key]?.pcHours : convertNumberToString((Number(rowItem?.pcHours).toFixed(2))).toString() : "0";
      
      cloneRowItem.pcValue =
        selectedCountry.code != "US" && item.DisplayProperty != "pcHours"
          ? convertFromUSDToOther(rowItem?.pcValue  ?? "0", currency)
          : rowItem?.pcValue;

      cloneRowItem.savings =
        selectedCountry.code != "US" && item.DisplayProperty != "pcHours"
          ? convertFromUSDToOther(rowItem.savings ?? "0", currency).toString()
          : rowItem?.savings?.toString() ?? "0";
      
          cloneRowItem.checked = true;
      return {
        ...acc,
        [item.key]: cloneRowItem,
      };
    }, {} as any);

    setEmployeeExperience((preState) => {
      const updatedState = data;
      return updatedState;
    });


  }, [currency, tcoCompleteReportMultipleDevice]);

  const onCheckboxChange = (key: string, displayProperty: string, tcoreport: any, checked: boolean, value: string) => {
    const copiedTcoReportPayloadList = buildTcoReportPayloadList(value, key, displayProperty, tcoreport, checked)
    setTcoReportPayloadList(copiedTcoReportPayloadList);
  }

  return (
    <Stack
      horizontal
      wrap
      tokens={{ childrenGap: 20 }}
      styles={{ root: { padding: "20px 0px 0px 50px" } }}
      style={{width: "80%"}}
    >
      {EmployeeExperienceData?.map((item) => (
        <Stack style={{ width: item.label ? "57%" : "43%" }} horizontal>
          <Switch
            className={styles.switchIndicator}
            checked={
                ((employeeExperience as any)[item.key]?.checked as boolean) ?? false
            }
            onChange={(e) => {
              const isChecked = e.target.checked ?? false;
              setEmployeeExperience((prevState) => ({
                    ...prevState,
                    [item.key]: {
                      ...(prevState as any)[item.key],
                        checked: isChecked,
                    },
                }));
                onCheckboxChange( item.key, item.DisplayProperty, employeeExperience as any,isChecked, ((employeeExperience as any)[item.key] as any)?.[item.DisplayProperty])
            }}
          />
          <StackItem grow style={{ width: "45%" }} key={item.key}>
          {
            !item.noInput ? 
            <LabelledInput
              label={item.label}
              sublabel={item.isAmount ? "Other PC Value" : "Other PC Hours"}
              isHeadingBold={true}
              isAmount={item.isAmount}
              infoLabel={item.info ?? ""}
              value={`${
                ((employeeExperience as any)[item.key] as any)?.[item.DisplayProperty] 
                }`.toString()}
              onChange={(ev, data) => {
                onChange(
                  ev,
                  data,
                  employeeExperience as any,
                  item.key,
                  item.DisplayProperty
                );
              }}
              onBlur={() => activeInputFieldKey = null}
              disabled={!((employeeExperience as any)[item.key] as any)?.checked as boolean ?? false}
            /> : 
            <InfoLabel
              info={item.info}
              style={{ fontWeight: 600 }}
              className={Tooltip.tooltipStyle}
            >
              {item.label}
            </InfoLabel>
          }
          </StackItem>
          {
            item.noInput && ((employeeExperience as any)[item.key] as any)?.[item.DisplayProperty] && 
            <Text>{currency?.symbol} {formatNumber(((employeeExperience as any)[item.key] as any)?.[item.DisplayProperty])}</Text>
          }
        </Stack>
      ))}
    </Stack>
  );
};

export default EmployeeExperience;
