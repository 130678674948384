import { mergeStyleSets, Stack, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { styles } from "./commonStyles";
import { InfoLabel, Switch } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput, { Tooltip } from "../common/LabelledInput";
import { AdvancedInput, AdvancedInputItem } from "v2/types/advancedInput";
import tooltipData from "v2/store/tooltipData.json";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
} from "v2/utilities/currencyConverter";
import { convertStringToNumber } from "utils/helpers";
import { set } from "lodash";
import { formatNumber } from "v2/utilities/helper";

const savingsStyles = mergeStyleSets({
  switchIndicator : {
      "& .fui-Switch__indicator":{
        marginLeft: "0px",
        marginTop: "0px"
      }
    }
});

interface DeviceSavingsProps {
  device: string; // Add your desired prop here
}
const DeviceSavings: FC <DeviceSavingsProps> = ({ device }) => {
  const { tcoCompleteReport, setTcoReportPayload,tcoReportPayload, currency,tcoCompleteReportMultipleDevice, tcoReportPayloadList,setTcoReportPayloadList} =
    useTcoCalculationContext();
  const [deviceSavings, setDeviceSavings] = useState<Partial<AdvancedInput>>(
    {} as AdvancedInput
  );

  const getTcoReportData = () => {
    const totalSavings = tcoCompleteReportMultipleDevice.deviceResponse.find(i=>i.device==device)?.totalSavings.rows;
    if (totalSavings === undefined || totalSavings === null){
      return deviceSavings
    };
    if(totalSavings.length === 0){
      return {
        ...deviceSavings,
        increasedResidualValue: {
          ...deviceSavings.increasedResidualValue,
          checked: false
        },
        reducedSupport: {
          ...deviceSavings.reducedSupport,
          checked: false
        },
        accessoriesSavings: {
          ...deviceSavings.accessoriesSavings,
          checked: false
        }

      }
    }

    let data: Partial<AdvancedInput> = { ...deviceSavings };

    totalSavings.forEach((row) => {
      if (row.label === "Increased Residual Value") {
        data.increasedResidualValue = {
          ...data.increasedResidualValue,
          checked: true,
          surfaceValue: row.surfaceValue.toString(),
          pcValue: row.pcValue.toString(),
          value: row.surfaceValue.toString(),
        } as AdvancedInputItem;
      } else if (row.label === "Reduced Support") {
        data.reducedSupport = {
          ...data.reducedSupport,
          checked: true,
          surfaceValue: row.surfaceValue.toString(),
          pcValue: row.pcValue.toString(),
          value: row.surfaceValue.toString(),
        } as AdvancedInputItem;
      } else if (row.label === "Accessories Savings") {
        data.accessoriesSavings = {
          ...data.accessoriesSavings,
          value: row.savings.toString(),
          checked: true,
        } as AdvancedInputItem;
      }
    });

    return data;
  };

  
  useEffect(() => {
    const data = getTcoReportData();
    setDeviceSavings(data);
  }, []);
 
  useEffect(() => {
    const convert = (value: string) => {
      if (currency.countryCode === "US") return value;
      return convertFromUSDToOther(
        convertStringToNumber(value),
        currency
      ).toString();
    };
    const advancedInputs = getTcoReportData();
    if (!advancedInputs) return;
    setDeviceSavings((prev) => {
      const updatedState = { ...prev };
      deviceSavingsData.forEach((key) => {
        const itemKey = key.key as keyof AdvancedInput;
        const item = prev[itemKey];
        if (item) {
          updatedState[itemKey] = {
            ...item,
            surfaceValue: convert(
              advancedInputs?.[itemKey]?.surfaceValue ?? "0"
            ),
            pcValue: convert(advancedInputs?.[itemKey]?.pcValue ?? "0"),
            value: convert(advancedInputs?.[itemKey]?.value ?? "0"),
            checked: advancedInputs?.[itemKey]?.checked ?? false
          };
        }
        else{
          updatedState[itemKey] = {
            surfaceValue: convert(
              advancedInputs?.[itemKey]?.surfaceValue ?? "0"
            ),
            pcValue: convert(advancedInputs?.[itemKey]?.pcValue ?? "0"),
            value: convert(advancedInputs?.[itemKey]?.value ?? "0"),
            checked: advancedInputs?.[itemKey]?.checked ?? false
          };
        }
      });
      return updatedState;
    });
  }, [currency, tcoCompleteReportMultipleDevice]);

  const onChange = (inputKey: string, subLabel: string, value: string) => {

    const newDeviceSavings = buildNewDeviceSavings(deviceSavings, inputKey, subLabel, value);
    
    setDeviceSavings(newDeviceSavings);

    const key = inputKey as keyof AdvancedInput;
    const convert = (value: string) => {
      if (currency.countryCode === "US") return value;
      return convertFromOtherToUSD(
        convertStringToNumber(value),
        currency
      ).toString();
    };
    if (newDeviceSavings && newDeviceSavings[key]) {
      let updatedData = { ...newDeviceSavings[key] };
      if (!updatedData) return;
      updatedData = {
        ...updatedData,
        value: convert(updatedData.surfaceValue ?? "0"),
        surfaceValue: convert(updatedData.surfaceValue ?? "0"),
        pcValue: convert(updatedData.pcValue ?? "0"),
      };
      setTcoReportPayload((prev) => ({
        ...prev,
        advancedInput: {
          ...prev?.advancedInput,
          [key]: updatedData,
        },
      }));

      setTcoReportPayloadList((prev) => {
        const updatedAdvancedInput = {
          ...(prev.payloadList.find(i=>i.deviceMixPortfolio[0].product==device)?.advancedInput ?? ({} as AdvancedInput)),
          [key]: updatedData}
          var item= tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product==device);
          if(item)
          {
           item.advancedInput = updatedAdvancedInput;
          }     
          return tcoReportPayloadList;  
      }
      );
    }
  };


  const buildNewDeviceSavings = (prev: Partial<AdvancedInput>, key: string, subLabel: string, value: string) => {
    if (!prev) {
      return prev;
    }
    const updatedValue = value;
    const keyToUpdate =
      subLabel === "Surface value" ? "surfaceValue" : "pcValue";
    const changeFlag =
      subLabel === "Surface value"
        ? "isSurfaceValueChanged"
        : "isOtherPCValueChanged";
    const currentKey = key as keyof AdvancedInput;
    const updatedState = {
      ...prev,
      [currentKey]: {
        ...prev[currentKey],
        [keyToUpdate]: updatedValue,
        [changeFlag]: true,
        value:
          subLabel === "Surface value"
            ? updatedValue
            : prev[currentKey]?.value,
      },
    };
    return updatedState;
  }

  const getInfo = (info: string) => {
    return <Stack>{info}</Stack>;
  };

  const deviceSavingsData = [
    {
      label: "Increased Residual Value",
      info: getInfo(tooltipData.increasedResidualValue),
      subLabels: ["Surface value", "Other PC value"],
      key: "increasedResidualValue",
      data: deviceSavings?.increasedResidualValue,
    },
    {
      label: "Reduced Support",
      info: "Of the total respondents surveyed, 25 percent who used Surface were able to retire or avoid purchasing security solutions or third-party services. This context included a one-time cost of $2 for deployment-related support, an annual cost of $5 for ongoing management, and an annual cost of $14 for security solutions or services per device. This translates to a three-year benefit of $58. (Refer to IDC study.)",
      subLabels: ["Surface value", "Other PC value"],
      key: "reducedSupport",
      data: deviceSavings?.reducedSupport,
    },
    {
      label: "Accessories Savings",
      info: "This includes the total accessories cost for ‟Other PC” from the Assumptions Editor. (Accessories such as Camera, Light, and Headset)",
      subLabels: [],
      key: "accessoriesSavings",
      data: deviceSavings?.accessoriesSavings,
      readOnly: true
    },
  ];

  const onCheckboxChange = (fieldKey: keyof AdvancedInput, checked: boolean) => {
    setTcoReportPayload((prev) => {
        const updatedAdvancedInput = {
            ...(prev.advancedInput ?? ({} as AdvancedInput)),
            [fieldKey]: {
                ...deviceSavings[fieldKey],
                checked: checked,
            },
        };
        if (checked) {
            delete updatedAdvancedInput[fieldKey];
        }
        return {
            ...prev,
            advancedInput: updatedAdvancedInput,
        };
    });
    
  setTcoReportPayloadList((prev) => {
        const updatedAdvancedInput = {
            ...(prev.payloadList.find(i=>i.deviceMixPortfolio[0].product===device)?.advancedInput ?? ({} as AdvancedInput)),
            [fieldKey]: {
                ...deviceSavings[fieldKey],
                checked: checked,
            },
        };
        var item= tcoReportPayloadList.payloadList.find(i=>i.deviceMixPortfolio[0].product===device);

        if(item)
        {
         item.advancedInput = updatedAdvancedInput;
        }            
        return tcoReportPayloadList;      
      }
    );
  }

  return (
    <Stack className={styles.childContainer} tokens={{ childrenGap: 16 }}>
      {deviceSavingsData?.map((item) => {
        return (
          <>
          <Stack horizontal>
            <Switch
              className={savingsStyles.switchIndicator}
              checked={
                  (deviceSavings[item.key as keyof AdvancedInput]?.checked as boolean) ?? false
              }
              onChange={(e) => {
                setDeviceSavings((prevState) => ({
                      ...prevState,
                      [item.key]: {
                          ...prevState[item.key  as keyof AdvancedInput],
                          checked: e.target.checked ?? false,
                      },
                  }));
                  onCheckboxChange(item.key as keyof AdvancedInput, e.target.checked);
              }}
            />
            <Stack style={{width: item.readOnly ? '26%' : '70%'}} key={item.label} tokens={{ childrenGap: 8 }}>
              <InfoLabel
                info={item.info}
                style={{ fontWeight: 600 }}
                className={Tooltip.tooltipStyle}
              >
                {item.label}
              </InfoLabel>
              <Stack horizontal tokens={{ childrenGap: 16 }}>
                {item.subLabels?.map((subLabel) => (
                  <LabelledInput
                    key={subLabel}
                    label={subLabel}
                    value={
                      subLabel === "Surface value"
                        ? `${item.data?.surfaceValue}`
                        : `${item.data?.pcValue}`
                    }
                    isAmount={true}
                    onChange={(ev, data) => {
                      onChange(item.key, subLabel, data.value);
                    }}
                    disabled={!deviceSavings[item.key as keyof AdvancedInput]?.checked ?? false}
                  />
                ))}
              </Stack>
            </Stack>
            {
              item.readOnly && item.data?.value && 
              <Text>{currency?.symbol} {formatNumber(item.data?.value)}</Text>
            }
          </Stack>
          </>
        );
      })}
    </Stack>
  );
};

export default DeviceSavings;
