import { FC, useEffect, useRef, useState } from "react";
import { IStackTokens, Stack, StackItem, Text } from "@fluentui/react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleData,
  Divider,
  Link,
  makeStyles,
  Switch,
} from "@fluentui/react-components";
import { BussinessAssumption } from "v2/pages/tco/BusinessCaseAssumptions";
import { Calculator } from "v2/assets/icons/Calculator";
import { FeedbackHub } from "v2/assets/icons/FeedbackHub";
import { Magnifier } from "v2/assets/icons/Magnifier";
import { Savings } from "v2/assets/icons/Savings";
import { Message } from "v2/assets/icons/Message";
import { People } from "v2/assets/icons/People";
import { Weather } from "v2/assets/icons/Weather";
import { OnScreenKeyboard } from "v2/assets/icons/OnScreenKeyboard";
import { FluentIcon } from "@fluentui/react-icons";
import { set } from "lodash";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { AdvancedInput } from "v2/types/advancedInput";
import { TcoReportRow } from "v2/types/apiResponseTypes";
import assumptionDataDevices from "v2/components/business-assumptions/BusinessAssumptionDataDevices.json";

interface AssumptionDetailPanelProps {
  data: BussinessAssumption;
  children: React.ReactNode;
  Id: string;
  openItems: string[];
  device: String;
}

const iconMap: { [key: string]: FluentIcon } = {
  Calculator,
  FeedbackHub,
  Magnifier,
  Message,
  OnScreenKeyboard,
  People,
  Savings,
  Weather,
};

const useStyles = makeStyles({
  card: {
    padding: "24px",
    borderRadius: "8px",
    border: "1px #F4F4F4",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
  },
  headerIcon: {
    "& .f9c4gz4": {
      alignSelf: "start",
    },
  },
  switchIndicator : {
    "& .fui-Switch__indicator":{
      marginLeft: "0px"
    }
  }
});

const numericalSpacingStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

export const assumptionDeviceMap: Record<keyof typeof assumptionDataDevices, Array<keyof AdvancedInput>> = {
  deviceCost: ["deviceCosts", "accessoriesCosts", "m365Costs", "maintenanceCosts"],
  deviceSavings: ["increasedResidualValue", "reducedSupport", "accessoriesSavings"],//accessoriesSavings added for select all bug
  itEfficiency: ["deviceConsolidation", "ongoingMaintenance", "helpdeskSupport", "securityIncidentResponse", "reducedITDeploymentCosts", "ongoingSecurity", "itTraining"],
  employeeExperience: ["employeeTimeLosttoHelpdesk", "meetingTime", "bootupTime", "employeeTimeLostToSecurityIncidents", "surfaceDeployment", "productivityGains", "employeeRetention"]
}

const AssumptionDetailPanel: FC<AssumptionDetailPanelProps> = (
  props: AssumptionDetailPanelProps
) => {
  let { data, children, Id, openItems,device } = props;
  const Icon = iconMap[data.iconName ?? ""];
  const styles = useStyles();
  const focusDivRef = useRef(null);

  const [initialOpenItems, setinitialOpenItemsList] = useState<string[]>([]);
  const [checked, setChecked] = useState<boolean>(true);
  const [enableSelectAllLink, setEnableSelectAllLink] = useState<boolean>(true);

  let [openItemsList, setOpenItemsList] = useState<string[]>([]);

  const { tcoCompleteReportMultipleDevice,tcoReportPayloadList, setTcoReportPayloadList } = useTcoCalculationContext();

  useEffect(() => {
    if (openItems.length > 0) {
      if (Id.toString() === openItems.toString()) {
        setinitialOpenItemsList([openItems.toString()]);
        setOpenItemsList([openItems.toString()]);
      }
    }
  }, [openItems]);

  const ontoggle = (ev: React.ChangeEvent<any>, data: AccordionToggleData) => {
    const value = data.value as string;

    if (!initialOpenItems.includes(value)) {
      initialOpenItems.push(value);
      setOpenItemsList([...initialOpenItems]);
    } else {
      initialOpenItems.pop();
      setOpenItemsList([...initialOpenItems]);
    }
  };


  useEffect(() => {
    let matchingRow: TcoReportRow[] | undefined;
    let matchingRowAll: TcoReportRow[] | undefined;

    const deviceSavingsHiddenInputLabels = ["Accessories Savings"]
    const employeeExperienceHiddenInputLabels = ["Higher Employee Retention", "Productivity Gains from Increased Mobility"]

    switch (Id) {
        case 'deviceCost':
            matchingRow = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.totalCosts?.rows
            matchingRowAll = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.totalCosts?.rows
            break;
        case 'deviceSavings':
            matchingRow = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.totalSavings?.rows?.filter(x => !deviceSavingsHiddenInputLabels.includes(x.label))
            matchingRowAll = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.totalSavings?.rows
            break;
        case 'employeeExperience':
            matchingRow = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.employeeEmpowerment?.rows?.filter(x => !employeeExperienceHiddenInputLabels.includes(x.label))
            matchingRowAll = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.employeeEmpowerment?.rows
            break; 
        case 'itEfficiency':
            matchingRow = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.itSimplification?.rows
            matchingRowAll = tcoCompleteReportMultipleDevice.deviceResponse?.find(i => i.device === device)?.itSimplification?.rows
            break;        
     
    }
    //"Accessories Savings" in response for deviceSavings if other fields removed/checked: false
    const toggleValue = matchingRow && matchingRow?.length > 0 ? true : false;
    const selectAllValue = matchingRowAll && matchingRowAll?.length === assumptionDeviceMap[Id as keyof typeof assumptionDataDevices].length ? true : false;
    setChecked(toggleValue)
    setEnableSelectAllLink(selectAllValue)

}, [tcoCompleteReportMultipleDevice, Id, device])

  const onCheckboxChange = (id: keyof typeof assumptionDataDevices, checked: boolean) => {

    let updatedAdvancedInput: Record<string, {checked: boolean}> = {}
    assumptionDeviceMap[id].forEach(fieldKey => {
      const previousValues = tcoReportPayloadList.payloadList.find(x => x.deviceMixPortfolio[0]?.product === device)?.advancedInput?.[fieldKey]
      updatedAdvancedInput[fieldKey] = {...previousValues, checked}
    })

    setTcoReportPayloadList((prev) => {
      return {
        payloadList : prev.payloadList.map(x => {
          if(x.deviceMixPortfolio[0]?.product === device){
            return {
              ...x,
              advancedInput:{...x.advancedInput, ...updatedAdvancedInput}
             } 
          }
          else{
            return {
              ...x
             }
          }
          
        })
      };      
  })


}

  return (
    <Stack className={styles.card}>
      <Accordion
        collapsible
        openItems={openItemsList}
        onToggle={(ev, data) => {
          ontoggle(ev, data);
        }}
      >
        <AccordionItem value={Id}>
          <AccordionHeader
            expandIconPosition="end"
            className={styles.headerIcon}
          >
            <Stack tokens={{ childrenGap: 16 }} horizontal>
              <StackItem>{Icon && <Icon fontSize={"32"} />}</StackItem>
              <Stack tokens={{ childrenGap: 9 }}>
                <Stack horizontal verticalAlign="center">
                  <Text style={{ fontWeight: "700" }}>{data.title}</Text>
                </Stack>
                <Text>{data.subTitle}</Text>
                {
                    /* condition to hide toggle for All device tab */
                    device?.length > 0 && (
                      <div style={{width: "fit-content"}} onClick={(e) => e.stopPropagation()}>
                        <Switch className={styles.switchIndicator} onClick={(e) => e.stopPropagation()} checked={checked}
                        onChange={(e) => {onCheckboxChange(Id as keyof typeof assumptionDataDevices, e.target.checked)}}
                        label={"Include in TCO Report"}/>
                      </div>
                      
                    )
                  }
              </Stack>
            </Stack>
          </AccordionHeader>
          <div id={Id} ref={focusDivRef}>
            <AccordionPanel>
              {
                device?.length > 0 && (
                  <Stack style={{marginLeft: "5.3%"}}>
                    <Divider style={{marginTop: "10px"}} />
                    <Stack style={{width:"fit-content", paddingLeft: "2px"}} tokens={numericalSpacingStackTokens} horizontal>
                      <Link onClick={(e) => {onCheckboxChange(Id as keyof typeof assumptionDataDevices, true)}} disabled={enableSelectAllLink}>Select all</Link>
                      <Divider vertical />
                      <Link onClick={(e) => {onCheckboxChange(Id as keyof typeof assumptionDataDevices, false)}} disabled={!checked}>Unselect all</Link>
                    </Stack>
                  </Stack>
                )
              }
              {children}
            </AccordionPanel>
          </div>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default AssumptionDetailPanel;
