import { BrowserRouter, Routes, Route } from "react-router-dom";
import SustainabilityCalculator from "./pages/sustainability/SustainabilityCalculator";
import TcoCalculator from "./pages/tco/TcoCalculator";
import { AlertBanner } from "./components/Alert";
import TcoUnderstand from "v2/pages/tco/TcoUnderstand";
import SustainabilityHome from "pages/sustainability/SustainabilityHome";
import SustainabilityUnderstand from "pages/sustainability/SustainabilityUnderstand";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import ChatDownload from "pages/ChatDownload";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import TcoLayout from "v2/pages/tco/TcoLayout";
import CustomerInfoPage from "v2/pages/tco/CustomerInfoPage";
import TcoLandingPage from "v2/pages/tco/TcoLandingPage";
import ChooseProductMix from "v2/pages/tco/ChooseProductMix";
import BusinessCaseAssumptions from "v2/pages/tco/BusinessCaseAssumptions";
import TcoCompleteReport from "v2/pages/tco/TcoCompleteReport";
import { makeStyles } from "@fluentui/react-components";
import OnePageReportDownload from "v2/pages/tco/OnePageReportDownload"

const useStyles = makeStyles({
  footer: {
    "& .c-uhff": {
      marginTop: "0px !important",
    },
  },
});

function App() {
  const styles = useStyles();

  const handleAcceptCookie = () => {
    //ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const updateTracking = (path, title) => {
    ReactGA.send({ hitType: "pageview", page: path, title: title });
  };

  const queryClient = new QueryClient();

  return (
    <div className="App">
      <AlertBanner />
      <div className="content-wrapper">
        <QueryClientProvider client={queryClient}>
          <FluentProvider theme={webLightTheme}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<TcoLandingPage />} />
                <Route path="" element={<TcoLayout />}>
                <Route
                    path="/one-page-report-download"
                    element={<OnePageReportDownload />}
                  />
                  <Route
                    path="/tell-us-about-yourself"
                    element={<CustomerInfoPage />}
                  />
                  <Route
                    path="/choose-your-surface-mix"
                    element={<ChooseProductMix />}
                  />
                  <Route
                    path="/review-business-case-assumptions"
                    element={<BusinessCaseAssumptions />}
                  />
                  <Route path="/tco-report" element={<TcoCompleteReport />} />
                </Route>

                <Route
                  path="/idc"
                  element={<TcoUnderstand />}
                  Component={updateTracking("/idc", "TCO Understanding Page")}
                />
                <Route
                  path="/calculator"
                  element={<TcoCalculator />}
                  Component={updateTracking(
                    "/calculator",
                    "TCO Calculator Page"
                  )}
                />
                <Route
                  path="/sustainability"
                  element={<SustainabilityHome />}
                  Component={updateTracking(
                    "/sustainability",
                    "Sustainability Landing Page"
                  )}
                />
                <Route
                  path="/sustainability/introduction"
                  element={<SustainabilityUnderstand />}
                  Component={updateTracking(
                    "/sustainability/introduction",
                    "Sustainability Understanding Page"
                  )}
                />
                <Route
                  path="/sustainability/calculator"
                  element={<SustainabilityCalculator />}
                  Component={updateTracking(
                    "/sustainability/calculator",
                    "Sustainability Calculator Page"
                  )}
                />
                {process.env.REACT_APP_BRANCH === "dev" ? (
                  <Route path="/download/chat" element={<ChatDownload />} />
                ) : null}
              </Routes>
            </BrowserRouter>
          </FluentProvider>
        </QueryClientProvider>
      </div>
      <div id="app-footer" className={styles.footer}
      ></div>
    </div>
  );
}

export default App;
