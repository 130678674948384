import { formatCurrency } from "v2/utilities/currencyConverter";
import { getPercentage } from "v2/utilities/helper";
import descriptions from "v2/components/report-page/ReportDescriptions.json";
import { TcoFinalReport, TcoReportSegment } from "v2/types/apiResponseTypes";

const updateRowDescription = (
  reportSection: any,
  oldLabel: string,
  description: string
) => {
  const row = reportSection?.rows?.find(
    (item: any) => item?.label === oldLabel
  );

  if (row) {
    if (description !== "") {
      switch (row.label) {
        case "Productivity Gains from Increased Mobility":
          row.description = "+ 11.6" + " " + `${description}`;
          break;
        case "Reduced Employee Time Lost to Helpdesk":
          row.description = "+ 5.5" + " " + `${description}`;
          break;
        case "Meeting Time Saved":
          row.description = "+ 7.7" + " " + `${description}`;
          break;
        case "Device Consolidation":
          row.description = "44%" + " " + `${description}`;
          break;
        case "Reduced Helpdesk Support":
          row.description = "49%" + " " + `${description}`;
          break;
        case "Reduced IT Deployment Costs":
          row.description = "21%" + " " + `${description}`;
          break;
        case "Reduced IT Staff Time for Ongoing Security":
          row.description = "3%" + " " + `${description}`;
          break;
        case "Reduced Ongoing Maintenance":
          row.description = "40%" + " " + `${description}`;
          break;
        case "Higher IT Training Cost":
          row.description = "6%" + " " + `${description}`;
          break;
        case "Reduced Time for Security Incident Response":
          row.description = "34%" + " " + `${description}`;
          break;

        case "Reduced Employee Time Lost to Security Incidents":
          row.description = "30%" + " " + `${description}`;
          break;
        case "Faster Surface Deployment to Employees":
          row.description = "27%" + " " + `${description}`;
          break;
        case "Higher Employee Retention":
          row.description = "4%" + " " + `${description}`;
          break;

        case "Increased Residual Value":
          row.description = "31%" + " " + `${description}`;
          break;

        case "Reduced Support":
            row.description = "23%" + " " + `${description}`;
            break;

        case "Accessories Savings":
              row.description = "" + " " + `${description}`;
              break;

        case "Surface Device":
              row.description = description;
              break;     
              


        default:
               debugger;
          row.description = `${getPercentage(
            row.savings,
            row.pcValue
          )}% ${description}`;
      }
    }
  }
};

const updateRowLabel = (
  reportSection: any,
  oldLabel: string,
  newLabel: string
) => {
  const row = reportSection?.rows?.find(
    (item: any) => item?.label === oldLabel
  );
  if (row) {
    if (newLabel !== "") {
      row.label = newLabel;
    }
  }
};

const updateRowLabelWithoutPercentage = (
  reportSection: any,
  oldLabel: string,
  newLabel: string,
  description: string,
  currency: any,
  selectedYears: number = 3
) => {
  const row = reportSection?.rows?.find(
    (item: any) => item?.label === oldLabel
  );
  if (row) {
    if (newLabel) {
      row.label = newLabel;
    }
    if (oldLabel === "Total M365 Licensing Costs") {
      row.description = `${formatCurrency(
        row.surfaceValue / (12 * selectedYears),
        currency?.symbol ?? "$",
        0
      )} ${description}`;
    } else if (description !== "" && row.label === "Faster Boot-up Time") {
      row.description = `32% ${description}`;
    }
  }
};

const updateRowLabelAndDescription = (
  reportSection: any,
  oldLabel: string,
  newLabel: string,
  description: string,
  currency: any,
  selectedYears: number = 3
) => {
  const row = reportSection?.rows?.find(
    (item: any) => item?.label === oldLabel
  );
  if (row) {
    if (newLabel) {
      row.label = newLabel;
    }
    if (oldLabel === "Total M365 Licensing Costs") {
      row.description = `$36 ${description}`;
    } 
    else if (oldLabel === "IT Training Costs") 
    {
      row.description = `6% ${description}`;
    }
   
    else if (description !== "") {
      row.description = `${getPercentage(
        row.savings,
        row.pcValue
      )}% ${description}`;
    }
  }
};

const updateFooter = (
  reportSection: TcoReportSegment | undefined,
  savings: number | undefined,
  conversionRate: number,
  currencySymbol: string,
  footerText: string
) => {
  if (reportSection && savings !== undefined) {
    reportSection.footer = `${formatCurrency(
      savings * conversionRate,
      currencySymbol,
      0
    )} ${footerText} `;
  }
};

const updateTcoCompleteReport = (
  tcoCompleteReport: any,
  tcoReportPayload: any,
  currency: any,
  selectedYears: number = 3
) => {
  const { itSimplification, employeeEmpowerment, totalCosts, totalSavings } =
    tcoCompleteReport;
  const conversionRate = tcoReportPayload.currencyConversionMultipleFromUSD;
  const currencySymbol = currency?.symbol ?? "$";

  let tcoCompleteReportUpdated: TcoFinalReport =
    structuredClone(tcoCompleteReport);

  if (tcoCompleteReportUpdated.itSimplification)
    tcoCompleteReportUpdated.itSimplification.label = "IT efficiency";
  if (tcoCompleteReportUpdated.employeeEmpowerment)
    tcoCompleteReportUpdated.employeeEmpowerment.label = "Employee experience";
  if (tcoCompleteReportUpdated.totalCosts)
    tcoCompleteReportUpdated.totalCosts.label = "Costs";
  if (tcoCompleteReportUpdated.totalSavings)
    tcoCompleteReportUpdated.totalSavings.label = "Savings";

  descriptions.forEach(({ section, label, description }) => {
    const reportSection =
      tcoCompleteReportUpdated[section as keyof TcoFinalReport];
    if (reportSection) {
      if (
        typeof reportSection !== "number" &&
        "label" in reportSection &&
        reportSection.label === "Costs"
      ) {
        updateRowLabel(reportSection, label, description);
      } else {
        updateRowDescription(reportSection, label, description);
      }
    }
  });

  updateRowLabelAndDescription(
    tcoCompleteReportUpdated.itSimplification,
    "IT Training Costs",
    "Higher IT Training Cost",
    "Increased IT Staff time due to Surface training",
    currency
  );

  updateRowLabelWithoutPercentage(
    tcoCompleteReportUpdated.employeeEmpowerment,
    "Faster Bootup Time",
    "Faster Boot-up Time",
    "faster boot time, 21% fewer reboots with Surface",
    currency
  );

  updateRowLabelAndDescription(
    tcoCompleteReportUpdated.totalCosts,
    "Total M365 Licensing Costs",
    "Microsoft 365 Subscription",
    "per user per month. License cost doesn't change across device manufacturers",
    currency,
    selectedYears
  );

  updateRowDescription(
    tcoCompleteReportUpdated.totalCosts,
    "Surface Device",
    "Surface Laptop or 2-in-1"
  );

  updateFooter(
    tcoCompleteReportUpdated.itSimplification,
    tcoCompleteReportUpdated.itSimplification?.savings,
    conversionRate,
    currencySymbol,
    `Total IT Benefit per Surface device (over ${selectedYears} years)`
  );

  updateFooter(
    tcoCompleteReportUpdated.employeeEmpowerment,
    tcoCompleteReportUpdated.employeeEmpowerment?.savings,
    conversionRate,
    currencySymbol,
    `Total Employee Benefit per Surface device (over ${selectedYears} years)`
  );

  updateFooter(
    tcoCompleteReportUpdated.totalCosts,
    tcoCompleteReportUpdated.totalCosts?.surfaceValue,
    conversionRate,
    currencySymbol,
    `Total Cost per Surface device (incl. accessories) over ${selectedYears} years`
  );

  updateFooter(
    tcoCompleteReportUpdated.totalSavings,
    tcoCompleteReportUpdated.totalSavings?.savings,
    conversionRate,
    currencySymbol,
    `Total Cost per Surface device (incl. accessories) over ${selectedYears} years`
  );

  return tcoCompleteReportUpdated;
};

export {
  getPercentage,
  updateRowDescription,
  updateRowLabel,
  updateRowLabelAndDescription,
  updateTcoCompleteReport,
};
