import { Button, makeStyles, tokens } from "@fluentui/react-components";
import { FC } from "react";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { deviceMap, tcoAssumptionMap } from "v2/utilities/mapping";
import { useTcoAssumptions } from "v2/services/tcoServices";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";
import { Options, Sustainability, SustainabilityCategory } from "v2/types/apiPayloadTypes";
import { discountRate } from "v2/types/commonTypes";

const useClasses = makeStyles({
  iconArrowCounterclockwise: {
    color: tokens.colorBrandForeground2Hover,
  },

});

const defaultOptions: Options = {
  includeCameraPeripheralSavings: true,
  includeLightsPeripheralSavings: true,
  includeHeadsetPeripheralSavings: true,
  includeDockCost: true,
  includeTypeCoverCost: false,
  includePenCost: false,
  includeExtraPowerSupplyCost: false,
  includeDeviceConsolidationSavings: true,
  includeResidualValueSavings: true,
  include3rdPartyServicesSavings: true,
  wantAccessories: true,
  default: true,
  custom: false,
};

const defaultSustainabilityCategory: SustainabilityCategory = {
  checked: false,
  unitPrice: 0,
  surfaceValue: 0,
  otherPcValue: 0,
};
const defaultSustainability: Sustainability = {
  CarbonEmissions: defaultSustainabilityCategory,
  Energy: defaultSustainabilityCategory,
};

const DEFAULT_YEARS = 3;

const initialDisCountRate: discountRate = {
  pbp: 6.2,
  npv: 6.2,
};

export const TcoResetValuesButton:FC = () => {

    const { setTcoReportPayloadList, setTcoReportPayload, selectedDevices, selectedCountry, currency, setSelectedYears, setDisCountRate } = useTcoCalculationContext();
    const {data: assumptions}=useTcoAssumptions();
    const classes = useClasses();
    
    const resetValues = () => {

        if(assumptions){
            setTcoReportPayload((prev) => ({
                ...prev,
                assumptions: tcoAssumptionMap(assumptions),
                sustainability: defaultSustainability,
              }));

            setTcoReportPayloadList((prev) => ({
              ...prev,
              payloadList: selectedDevices.map((device) => ({
                assumptions: tcoAssumptionMap(assumptions),
                country: selectedCountry.code,
                currency: currency?.currencyCode as string,
                years: DEFAULT_YEARS,
                currencyConversionMultipleFromUSD: currency?.currencyPerUSD as number,
                currencyConversionMultipleToUSD: currency?.usdPerCurrency as number,
                deviceMixPortfolio: deviceMap([device]),
                options: defaultOptions,
                sustainability: defaultSustainability,
                industry: "default",
              })),
            }));

            setSelectedYears(DEFAULT_YEARS);

            setDisCountRate(initialDisCountRate);
        }
    }

    const onResetClick = () => {
        resetValues();
    }
    return (
        <Button appearance="subtle" className={classes.iconArrowCounterclockwise} icon={<ArrowCounterclockwise24Regular className={classes.iconArrowCounterclockwise} />} onClick={onResetClick}>Reset values</Button>
    )
}