import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Text,Image, mergeClasses } from '@fluentui/react-components';
import deviceCostImage from "assets/tco-device-cost.svg";
import { useNavigate } from 'react-router-dom';
import directSavingsImage from "assets/tco-direct-savings.svg";
import itEfficiencyImage from "assets/tco-it-efficiency.svg";
import employeeExperienceImage from "assets/tco-employee-experience.svg";
import itEfficiencyIcon from "../../assets/tco/it-efficiency-icon.svg";
import "./TcoUnderstand.scss";
import { convertNumberToString, trackGAEvent } from 'utils/helpers';
import { mergeStyleSets, Stack, StackItem, Text as FluentText } from '@fluentui/react';
import { myTheme } from 'v2/components/navbar/theme'
import { commonStyles } from './TcoLandingPage';
import AIpowerdicon from "v2/assets/tco/AI-powerd-icon.svg";
import SecurityIcon from "v2/assets/tco/security-icon.svg";
import InstantAccessIcon from "v2/assets/tco/instant-access-icon.svg";
import EasyToSetupIcon from "v2/assets/tco/easy-to-setup-icon.svg";
import SustainabilityIcon from "v2/assets/tco/sustainability-icon.svg";
import BestWayToWindowsIcon from "v2/assets/tco/best-way-windows-icon.svg";
import { BUSINESS_CASE_URL } from 'v2/constants';

type ItemThreeTableData = {
    label: string;
    description: string;
    values: {
        id: string;
        value: string;
        description: string;
    }[];
    total: {
        value: number;
        description: string;
    };
}

const TcoUnderstand = () => {

    const navigate = useNavigate();

    const [totalValues, setTotalValues] = useState({
        deviceCosts: 3238,
        directSavings: 293,
        itEfficiency: 5635,
        employeeExperience: 3108,
    });

    const [totalSavingsBenefits, setTotalSavingsBenefits] = useState(totalValues.directSavings + totalValues.employeeExperience + totalValues.itEfficiency);
    const [roi, setRoi] = useState((totalValues.directSavings + totalValues.employeeExperience + totalValues.itEfficiency) / totalValues.deviceCosts);

    useEffect(() => {
        setTotalSavingsBenefits((totalValues.directSavings + totalValues.employeeExperience + totalValues.itEfficiency));
        setRoi((totalValues.directSavings + totalValues.employeeExperience + totalValues.itEfficiency) / totalValues.deviceCosts)
    }, [totalValues])

    const groupsAndItems = {
        deviceCosts: ["deviceCosts", "m365Costs", "accessoriesCosts", "maintenanceCosts",],
        directSavings: ["increasedResidualValue", "reducedSupport", "accessoriesSavings",],
        itEfficiency: ["deviceConsolidation", "helpdeskSupport", "ongoingMaintenance", "securityIncidentResponse", "reducedITDeploymentCosts", "ongoingSecurity", "itTraining",],
        employeeExperience: ["productivityGains", "employeeTimeLosttoHelpdesk", "meetingTime", "employeeTimeLostToSecurityIncidents", "surfaceDeployment", "employeeRetention", "bootupTime",],
    };

    const itemsAndCosts = {
        deviceCosts: 1355,
        m365Costs: 1296,
        accessoriesCosts: 344,
        maintenanceCosts: 243,
        increasedResidualValue: 115,
        reducedSupport: 58,
        accessoriesSavings: 120,
        deviceConsolidation: 3756,
        helpdeskSupport: 885,
        ongoingMaintenance: 810,
        securityIncidentResponse: 126,
        reducedITDeploymentCosts: 42,
        ongoingSecurity: 18,
        itTraining: -2,
        productivityGains: 1290,
        employeeTimeLosttoHelpdesk: 619,
        meetingTime: 864,
        employeeTimeLostToSecurityIncidents: 89,
        surfaceDeployment: 60,
        employeeRetention: 105,
        bootupTime: 81,
    }

    const itemThreeTableData: ItemThreeTableData[] = [
        {
            label: "Device Cost",
            description: "Optimize spending with Surface's cost-effective, durable device lineup.",
            values: [
                {
                    id: "deviceCosts",
                    value: "$1,355",
                    description: "Average Surface Device",
                },
                {
                    id: "m365Costs",
                    value: "$1,296",
                    description: "M365 E3 subscription",
                },
                {
                    id: "accessoriesCosts",
                    value: "$344",
                    description: "Accessories",
                },
                {
                    id: "maintenanceCosts",
                    value: "$243",
                    description: "Extended warranty and maintenance",
                },
            ],
            total: {
                value: totalValues.deviceCosts,
                description: "Total 3-year cost per Surface",
            }
        },
        {
            label: "Direct Savings",
            description: "Reduce expenses through energy-efficient hardware and productivity-enhancing tools.",
            values: [
                {
                    id: "increasedResidualValue",
                    value: "$115",
                    description: "Increased residual value, 31% higher than other PC* devices",
                },
                {
                    id: "reducedSupport",
                    value: "$58",
                    description: "Reduction in IT costs from lower 3rd-party support and security expenses",
                },
                {
                    id: "accessoriesSavings",
                    value: "$120",
                    description: "Accessory Savings",
                },
            ],
            total: {
                value: totalValues.directSavings,
                description: "Device savings per Surface Device",
            }
        },
        {
            label: "IT Efficiency",
            description: "Streamline IT workflows with seamless management and reduced maintenance needs.",
            values: [
                {
                    id: "deviceConsolidation",
                    value: "$3,756",
                    description: "Device Consolidation",
                },
                {
                    id: "helpdeskSupport",
                    value: "$885",
                    description: "Reduced Helpdesk",
                },
                {
                    id: "ongoingMaintenance",
                    value: "$810",
                    description: "Reduced Maintenance",
                },
                {
                    id: "securityIncidentResponse",
                    value: "$126",
                    description: "Reduced Security Incident Response",
                },
                {
                    id: "reducedITDeploymentCosts",
                    value: "$42",
                    description: "Reduced Deployment Cost",
                },
                {
                    id: "ongoingSecurity",
                    value: "$18",
                    description: "Reduced IT Staff Time for Security",
                },
                {
                    id: "itTraining",
                    value: "-$2",
                    description: "Higher IT Training Cost",
                },
            ],
            total: {
                value: totalValues.itEfficiency,
                description: "Total IT Benefit per Surface Device",
            }
        },
        {
            label: "Employee Experience",
            description: "Boost satisfaction with reliable, flexible devices tailored to employee needs.",
            values: [
                {
                    id: "productivityGains",
                    value: "$1,290",
                    description: "Productivity Gains from Increased Mobility",
                },
                {
                    id: "employeeTimeLosttoHelpdesk",
                    value: "$619",
                    description: "Reduced Employee Time Lost to Helpdesk",
                },
                {
                    id: "meetingTime",
                    value: "$864",
                    description: "Meeting Time Saved",
                },
                {
                    id: "employeeTimeLostToSecurityIncidents",
                    value: "$89",
                    description: "Reduced Employee Time Lost to Security Incidents",
                },
                {
                    id: "surfaceDeployment",
                    value: "$60",
                    description: "Faster Deployment",
                },
                {
                    id: "employeeRetention",
                    value: "$105",
                    description: "Higher Employee Retention",
                },
                {
                    id: "bootupTime",
                    value: "$81",
                    description: "Faster Bootup Time",
                },
            ],
            total: {
                value: totalValues.employeeExperience,
                description: "Total Employee Benefit per Surface Device",
            }
        },
    ];

    const understandTcoStyles = mergeStyleSets({
        rootContainer: {
            display: "flex",
            flexDirection: "column",
        },
        container: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto"
        },
        left: {
            width: "25%",
            // width: "40%",
            display: "flex",
            flexDirection: "column",
        },
        right: {
            width: "73%",
            // width: "57%",
        },
        leftElement: {
            flex: 1,
            borderRight: "1px solid #bfbfbf",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: "#bfbfbf",
            // fontSize: "3.1rem",
            fontSize: "2.4rem",
            fontWeight: 340,
            textAlign: "right",
            paddingLeft: "2rem",
            paddingRight: "3rem",
            "p": {
                cursor: "pointer",
                strong: {
                    fontWeight: 500,
                }
            },
            "&.active": {
                color: "#4E95D9",
                borderRight: "1px solid #0070C0",
            },
            position: "relative",
        },
        emptyElement: {
            padding: "0.5rem 0",
        },
        triangle: {
            content: '""',
            position: "absolute",
            top: "50%",
            right: "-2rem",
            transform: "translateY(-50%)",
            borderLeft: "1rem solid #0070C0",
            borderTop: "1rem solid transparent",
            borderBottom: "1rem solid transparent",
            borderRight: "1rem solid transparent",
        },
        topRightButton: {
            marginRight: window.innerWidth > 1640 ? "4.6rem" : "4rem",
            border: "1px solid #4E95D9",
            color: "#4E95D9",
            borderRadius: "0.5rem",
            padding: "0.4rem 1.5rem",
            fontWeight: 500,
            ":hover": {
                background: "#4E95D9",
                color: "#ffffff",
                cursor: "pointer",
            }
        }
    });


    const itemThreeStyles = mergeStyleSets({
        container: {
            marginTop: window.innerWidth > 1740 ? "0rem" : "0",
            paddingTop: "1rem",
        },
        firstElement: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        secondElement: {
            marginTop: window.innerWidth > 1740 ? "3rem" : "2.5rem",
            border: "2px solid #f2f2f2",
            borderRadius: "8px",
            overflow: "hidden"
        },
        firstElementLeft: {
            width: "50%",
            paddingRight: "2rem",
            ">p:first-child": {
                fontSize: "2rem",
                marginTop: "0rem",
                marginBottom: "1rem",
                fontWeight: 370,
            },
            ">p:last-child": {
                fontSize: "1.1rem",
                marginTop: "0rem",
                span: {
                    fontWeight: 500,
                }
            },
        },
        firstElementRight: {
            width: "50%",
            paddingLeft: "3rem",
            borderLeft: "1px solid #f2f2f2",
        },
        description: {
            margin: "0",
            fontSize: "1.1rem",
            span: {
                fontWeight: 500,
            },
            "&.mb": {
                marginBottom: "0.7rem",
            }
        },
        value: {
            margin: "0",
            color: "#0B3566",
            fontWeight: "bold",
            fontSize: "1.8rem",
        },
        tableContainer: {
            display: "flex",
            alignItems: "stretch",
            // justifyContent: "center",
            "div:nth-child(1)": {
                ".tableHead": {
                    backgroundColor: "#315187",
                },
                ".tableBody": {
                    backgroundColor: '#F9FCFF',
                }
            },
            "div:nth-child(2)": {
                ".tableHead": {
                    backgroundColor: "#0B3566",
                },
                ".tableBody": {
                    backgroundColor: '#F1F4FC',
                }
            },
            "div:nth-child(3)": {
                ".tableHead": {
                    backgroundColor: "#315187",
                },
                ".tableBody": {
                    backgroundColor: '#F9FCFF',
                }
            },
            "div:nth-child(4)": {
                ".tableHead": {
                    backgroundColor: "#0B3566",
                },
                ".tableBody": {
                    backgroundColor: '#F1F4FC',
                }
            },

            ">div:nth-child(1)": {

                width: "100%",
            },
            ">div:nth-child(2)": {

                width: "100%",
            },
            ">div:nth-child(3)": {

                width: "100%",
            },
            ">div:nth-child(4)": {

                width: "100%",
            },
        },
        tableHead: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: 'white',
            flexDirection: 'column',
            paddingLeft: '1.5rem',
            paddingRight: '2rem',
            paddingTop: '1rem',
            paddingBottom: '1.5rem',
            maxHeight:"80px",
            img: {
                width: "1.5rem",
                height: "1.5rem",
                marginRight: "0.7rem",
            },
            p: {
                fontSize: "1rem",
                fontWeight: 500,
                marginTop: "0.8rem",
                marginBottom: "0.8rem",
            }
        },
        tableBody: {
            height: '95%',
            paddingRight: "1rem",
            ul: {
                margin: "0px",
                paddingLeft: "1rem",
            }
        },
        tableListItemBold: {
            fontWeight: 500,
        },
        tableTotalContainer: {
            display: "flex",
            alignItems: "stretch",
            marginTop: '3rem',
            // justifyContent: "center",
            ">div:nth-child(1)": {
                borderBottom: `8px solid #315187`,
            },
            ">div:nth-child(2)": {
                borderBottom: `8px solid #0B3566`,
            },
            ">div:nth-child(3)": {
                borderBottom: `8px solid #315187`,
            },
            ">div:nth-child(4)": {
                borderBottom: `8px solid #0B3566`,
            },

        },
        totalListItem: {
            boxSizing: "border-box",
            // width: "25%",
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            textAlign: "center",
            color: "#0078D4",
            fontSize: "14px",
            backgroundColor: "#fefefe",
            width: "100%",
            span: {
                fontWeight: 500,
            },
            ">p:nth-child(1)": {
                fontWeight: 500,
            },
            p: {
                margin: 0,
                padding: 0
            }
        },
        exceptionImage: {
            width: "1.8rem !important",
        },
        deviceCostImage: {
            height: "1.2rem !important"
        }
    });

    const handleCheckboxClick = (isChecked: boolean, id: keyof typeof itemsAndCosts) => {
        let group: keyof typeof groupsAndItems;
        if (groupsAndItems.deviceCosts.includes(id)) {
            group = "deviceCosts";
        } else if (groupsAndItems.directSavings.includes(id)) {
            group = "directSavings";
        } else if (groupsAndItems.itEfficiency.includes(id)) {
            group = "itEfficiency";
        } else if (groupsAndItems.employeeExperience.includes(id)) {
            group = "employeeExperience";
        }

        setTotalValues((state) => {
            return {
                ...state,
                [group]: isChecked ? state[group] + itemsAndCosts[id] : state[group] - itemsAndCosts[id]
            }
        });

    }

    const tableColumnContent = (item: ItemThreeTableData, index: number) => {

        let imageSource;
        switch (item.label) {
            case "Device Cost":
                imageSource = deviceCostImage;
                break;
            case "Direct Savings":
                imageSource = directSavingsImage;
                break;
            case "IT Efficiency":
                imageSource = itEfficiencyIcon;
                break;
            case "Employee Retention":
                imageSource = employeeExperienceImage;
                break;
            default:
                imageSource = itEfficiencyImage;
                break;
        }

        return (
            <div key={index}>
                <div className={`${itemThreeStyles.tableHead} tableHead`}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className={item.label === "IT Efficiency" ? `${itemThreeStyles.exceptionImage}` : item.label === "Device Cost" ? `${itemThreeStyles.deviceCostImage}` : ""} src={imageSource} alt={item.label} />
                        <p>{item.label}</p>
                    </div>
                    <span>{item.description}</span>
                </div>
                <div className={`${itemThreeStyles.tableBody} tableBody mobile-table-body`}>
                    <ul>
                        {item.values.map((listItem, i) => {
                            return (
                                <>
                                    <Checkbox defaultChecked className={`custom-checkbox`} label={<><span className={`${itemThreeStyles.tableListItemBold}`}>{listItem.value}</span>: {listItem.description}</>} onChange={(eve, isChecked) => handleCheckboxClick(isChecked.checked as boolean, listItem.id as keyof typeof itemsAndCosts)} />
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    const HeaderBanner = () => {
        const styles = mergeStyleSets({
            textColor: {
                color: "#fff",
            },
            heading: {
                fontSize: "50px",
                fontWeight: "600",
                lineHeight: "66.5px"
            },
            background: {
                background: "linear-gradient(87deg, #1A264A 0.7%, #445D89 55.44%)",
            }
        });

        return (
            <div className={styles.background}>
                <Stack
                    className={commonStyles.container}
                    horizontal
                    tokens={{ childrenGap: 80 }}
                    horizontalAlign="space-evenly"
                    verticalAlign="center"
                    style={{paddingLeft: "10%", paddingTop: "6rem", paddingBottom: "6rem"}}
                >
                    <Stack tokens={{ childrenGap: 19 }} style={{ width: "63%" }}>
                        <StackItem>
                            <FluentText className={mergeClasses(styles.heading, styles.textColor)}>
                                Outstanding performance meets ultimate flexibility
                            </FluentText>
                        </StackItem>
                        <StackItem>
                            <FluentText style={{fontWeight:"400", fontSize:"14px", lineHeight:"18.62px"}} className={styles.textColor}>
                                The Surface portfolio delivers experiences employees love with the choice and flexibility they need to work on their terms. Discover the savings and benefits these devices bring to your modern workplace.
                            </FluentText>
                        </StackItem>
                        <StackItem align="start">
                            <Button appearance="primary" onClick={() => { window.open('/tell-us-about-yourself','_blank'); trackGAEvent("Navigation", "TCO calculator button click on IDC page", "TCO Calculator button in Understand TCO page") }}>Start TCO Calculator</Button>
                        </StackItem>
                    </Stack>
                    <Stack style={{ width: "50%"}}>
                        <Image
                            alt="image"
                            width={463}
                            height={234}
                            src={require("../../assets/tco/MSFT-Home-Pro10-Laptop6-Hero-1.png")}
                        />
                    </Stack>
                </Stack>
            </div>
        )
    }

    const FooterBanner = () => {
        return (
            <div style={{ backgroundColor: myTheme.colorPaletteBlueForeground2, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '16rem', justifyContent: 'center' }}>
                <Text size={700} style={{ color: 'white' }}>Customize your report and see your</Text>
                <Text size={700} style={{ color: 'white' }}>Total Cost of Ownership</Text>
                <div style={{ marginTop: '2rem' }}>
                    <Button appearance="primary" onClick={() => { window.open('/tell-us-about-yourself','_blank'); trackGAEvent("Navigation", "TCO calculator button click on IDC page", "TCO Calculator button in Understand TCO page") }}>Start TCO Calculator</Button>
                </div>
            </div>
        )
    }

    const whySurfaceCardData = [
        {
            title: "AI-powered productivity",
            description: "Microsoft AI experiences are designed to put knowledge and expertise at your fingertips and help you take action, empowering businesses to meet any challenge and seize every opportunity.",
            icon: AIpowerdicon
        },
        {
            title: "Security backed by Microsoft",
            description: "Protected by Microsoft, every Surface delivers robust security with layers of extensive protection measures built in.",
            icon: SecurityIcon
        },
        {
            title: "Instant access to the apps you need",
            description: "Surface is optimized for Microsoft experiences like Windows 11 and Microsoft 365, so you can expect the apps you need to be up and running the moment you power on.",
            icon: InstantAccessIcon
        },
        {
            title: "Easy to set up and be productive",
            description: "Surface devices are built to be simple to set up, so you can be productive straight out of the box.",
            icon: EasyToSetupIcon
        },
        {
            title: "Designed with sustainability in mind",
            description: "Our commitment to sustainability and accessibility is heavily ingrained in our design process, from what our customers can achieve with our devices to what we leave behind for future generations.",
            icon: SustainabilityIcon
        },
        {
            title: "The best way to Windows",
            description: "As the only devices made by-and for-Microsoft, and with tools like Windows Studio Effects and Phone Link, Surface is the premier stage for Windows 11.",
            icon: BestWayToWindowsIcon
        }
    ]

    const WhySurfaceCard = (title: string, description: string, icon: any) => {

        return (
            <div style={{boxShadow: "0px 2px 4px 0px #00000024", border: "1px solid #015FCF", background: "linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 35%, #FFFFFF 82%, #F2F8FF 100%)", borderRadius: "24px", padding: "31px 29px 31px 29px", display: "flex", width: "43%", alignItems:"center", gap:"30px"}}>
                <div>
                <img src={icon} alt='why surface'></img>
                </div>
                <div style={{display: "flex", flexDirection: "column", gap:"5px"}}>
                    <Text style={{color:"#232C4C", fontFamily:"Segoe UI", fontSize:"20px", fontWeight:"600", lineHeight:"28px",}} >{title}</Text>
                    <Text style={{color:"#315187", lineHeight:"24px", fontSize:"16px", fontWeight:"400"}}>{description}</Text>
                </div>
            </div>
        )
    }

    return (
        <div className={`${understandTcoStyles.rootContainer}`} style={{background:"linear-gradient(332deg, #C1DBFC, #F4F9FF, transparent)"}}>
            <HeaderBanner></HeaderBanner>
            <div className={`${understandTcoStyles.container}`} style={{ alignItems: 'center' }}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '12rem', justifyContent: 'center', lineHeight: "42.56px"}}>
                    <span >
                        <Text style={{fontSize: "32px", fontWeight: "600", color: "#0B3566"}}>Designed by Microsoft.</Text><Text style={{ color: "#092DBD",fontSize: "32px", fontWeight: "600" }}> Built for business.</Text>
                    </span>
                    <Text style={{fontSize: "32px", fontWeight: "600", color:"#0B3566"}}>Inpired by the way you work.</Text>
                
                </div>
                <div style={{display: "flex", gap: "20px", flexWrap:"wrap"}}>
                    {whySurfaceCardData.map(x => WhySurfaceCard(x.title, x.description, x.icon))}
                </div>
                {/* <img width="85%" src={WhySurfaceCards} alt='why surface'></img> */}
                <Text style={{ marginTop: '8rem', marginBottom: '2rem' }}  size={800} weight='semibold' >Discover the value of Surface</Text>
                <Text align='center' style={{ marginBottom: '2rem', marginLeft: '4rem', marginRight: '4rem' }} size={400} >Outfitting your employees with the right tools for the job has never been more critical. IT teams are looking for ways to increase security, manage devices in the evolving hybrid workplace, and deliver powerful experiences that create productivity for employees. </Text>
                <Text align='center' style={{ marginBottom: '2rem', marginLeft: '2rem', marginRight: '2rem' }} size={400} >Recent <a style={{color: "#0078D4"}} href={BUSINESS_CASE_URL}>research from IDC</a> highlights that between direct savings, IT efficiency, and employee experience, Surface with Microsoft 365 doesn't just provide savings compared with other devices. It creates new value by enabling up to $9,036 in additional benefits over 3 years. <sup style={{color:"#0078D4"}}><a style={{textDecoration:"none"}} href="#reference1">1</a></sup></Text>
                <Text style={{ marginBottom: '2rem' }} size={400} >Explore the details below to see how the key pillars identified by IDC highlight the exceptional value Surface can bring to your organization.</Text>
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <div className='basic-card'>
                        <p className={`${itemThreeStyles.value} mobile-first-element-value`}>{convertNumberToString(totalSavingsBenefits, "$")}</p>
                        <p style={{ marginBottom: '0px', marginTop: '10px' }}>In total 3-year savings and benefits</p>
                    </div>
                    <div className='basic-card'>
                        <p className={`${itemThreeStyles.value} mobile-first-element-value`}>{roi.toFixed(1)}x</p>
                        <p style={{ marginBottom: '0px', marginTop: '10px' }}>3-year return on investment</p>
                    </div>
                </div>
                <div className={`${itemThreeStyles.secondElement}`}>
                    <div className={`${itemThreeStyles.tableContainer} mobile-table-container`}>
                        {itemThreeTableData.map((item, index) => tableColumnContent(item, index))}
                    </div>
                    <div className={`${itemThreeStyles.tableTotalContainer} mobile-table-total-container`}>
                        {
                            itemThreeTableData.map((item, index) => {
                                return (
                                    <div className={`${itemThreeStyles.totalListItem} mobile-table-total-list-item`}>
                                        <Text style={{fontSize: "18px", fontWeight:"700"}} >{convertNumberToString(item.total.value, "$")}</Text>
                                        <p>{item.total.description}</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div  style={{fontSize: "10px", marginTop: "2rem"}}>
                    <section id="reference1">
                        <div style={{lineHeight: "normal", marginTop: "1rem"}}>
                            <span style={{color:"#0078D4", textDecoration:"underline"}}>[1]</span> <a href={BUSINESS_CASE_URL}>IDC, 2024. </a><span>IDC Research Study conducted from surveys and interviews between December 2021 – February 2022. All respondents were IT decision-makers at large organizations (250-5000+ employees) representing organizations from the United States, Australia, India, Spain, France, United Kingdom, New Zealand, and Germany. Cost & Savings findings based on average cost and time estimates provided directly by respondents; actual costs and savings may vary based on your specific Device Mix and deployment. For the detailed study, <a href={BUSINESS_CASE_URL}>click here</a></span>
                        </div>
                    </section>
                </div>
                
               
            </div>

            <div style={{marginTop: "4rem"}}>
                <FooterBanner></FooterBanner>
            </div>
        </div>
    )
}

export default TcoUnderstand;