import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generateOnePager } from "v2/utilities/tco/pdfHelper";
import { TcoReportPayloadList } from "v2/types/apiPayloadTypes";
import CurrencySymbols from "v2/store/CurrencySymbols.json";
import { getOnePagerArguments, getReportShare, useTcoConfig } from "v2/services/tcoServices";
import { Currency } from "v2/types/currencyType";

const OnePageReportDownload: FC = () => {
  const [searchParams] = useSearchParams();
  const { data } = useTcoConfig();
  const navigate = useNavigate();

  useEffect(() => {
    generateReport();
  }, [data, searchParams]);

  const generateReport = async () => {
    const id = searchParams.get("id");
    if (id && data) {
      try {
        const payload = await getReportShare(id);
        const payloadList = JSON.parse(payload) as TcoReportPayloadList;
        const country = payloadList.payloadList[0].country;

        const currentCurrency = await getCurrency(country);
        if (currentCurrency) {
          const years = payloadList.payloadList[0].years;
          const args = await getOnePagerArguments(payloadList, payloadList.payloadList[0], currentCurrency, years);
          await generateOnePager(payloadList, args.perDeviceData.deviceResponse, args.updatedFinalReport, currentCurrency.symbol, years);
        };

      } catch (err) {
        console.error("Failed to parse data:", err);
      }
      finally{
        navigate("/tell-us-about-yourself");
      }
    }
  };

  const getCurrency = async (country: string) => {
    let currentCurrency = data?.currencies.find(
      (currency) => currency.countryCode === country
    );
    const symbol = CurrencySymbols.currencySymbols.find(
      (symbol) => symbol.code === currentCurrency?.currencyCode
    );
    return { ...currentCurrency, symbol: symbol?.symbol } as Currency | undefined
  }

  return <div>Processing your request...</div>;
}

export default OnePageReportDownload;