import { useQuery } from "@tanstack/react-query";
import { validateTcoReportPayload } from "v2/utilities/helper";
import { TcoReportPayload, TcoReportPayloadList } from "v2/types/apiPayloadTypes";
import {
  TcoAssumption,
  TcoFinalReport,
  TcoConfig,
  TcoReport,
  TcoFinalReportMultipleDevices,
} from "v2/types/apiResponseTypes";
import axiosClient from "v2/utilities/axiosClient";
import { roundValues } from "v2/utilities/helper";
import { CustomerDetail } from "v2/types/customerDetailType";
import { updateTcoCompleteReport } from "v2/utilities/reportHelpers";
import { Currency } from "v2/types/currencyType";

const API_CODE = process.env.REACT_APP_API_CODE;

export const useTcoConfig = () => {
  return useQuery({
    queryKey: ["tcoConfig"],
    queryFn: getTcoConfig,
    refetchOnWindowFocus: false,
  });
};

const getTcoConfig = async (): Promise<TcoConfig> => {
  const response = await axiosClient.post<TcoConfig>(
    "api/tco-config?code=" + API_CODE
  );
  return response.data;
};

export const useTcoAssumptions = () => {
  return useQuery({
    queryKey: ["tcoAssumptions"],
    queryFn: getTcoAssumptions,
    refetchOnWindowFocus: false,
  });
};

const getTcoAssumptions = async (): Promise<TcoAssumption[]> => {
  const response = await axiosClient.post<{
    value: { assumptions: TcoAssumption[] };
  }>("api/tco-assumptions?code=" + API_CODE);
  return response.data.value.assumptions;
};

export const useTcoReport = (data: TcoReportPayload) => {
  return useQuery({
    queryKey: ["tcoReport"],
    queryFn: () => getTcoReport(data),
    refetchOnWindowFocus: false,
    enabled: validateTcoReportPayload(data),
  });
};

const getTcoReport = async (data: TcoReportPayload): Promise<TcoReport> => {
  const response = await axiosClient.post<{ value: TcoReport }>(
    "api/tco-report?code=" + API_CODE,
    data
  );
  return response.data.value;
};

const getTcoCompleteReport = async (
  data: TcoReportPayload
): Promise<TcoFinalReport> => {
  const response = await axiosClient.post<{ value: TcoFinalReport }>(
    "api/tco-report-complete?code=" + API_CODE,
    data
  );
  const updatedValue = response.data.value;
  return updatedValue;
};

export const useTcoCompleteReport = (data: TcoReportPayload) => {
  return useQuery({
    queryKey: ["tcoCompleteReport"],
    queryFn: () => getTcoCompleteReport(data),
    refetchOnWindowFocus: false,
    enabled: validateTcoReportPayload(data),
  });
};

export const useTcoCompleteReportForTooltip = (data: TcoReportPayloadList) => {
  return useQuery({
    queryKey: ["tcoCompleteReportTooltip"],
    queryFn: () => getTcoCompleteReportForMultipleDevices(data),
    refetchOnWindowFocus: false,
    enabled: true,
  });
};


const getTcoCompleteReportForMultipleDevices = async (
  data: TcoReportPayloadList
): Promise<TcoFinalReportMultipleDevices> => {
  const response = await axiosClient.post<{ value: TcoFinalReportMultipleDevices }>(
    "api/tco-report-complete-Multiple-Devices?code=" + API_CODE,
    data
  );
  const updatedValue = response.data.value;
  return updatedValue;
};


export const useTcoCompleteReportForMultipleDevices = (data: TcoReportPayloadList) => {
  return useQuery({
    queryKey: ["tcoCompleteReportMultipleDevices"],
    queryFn: () => getTcoCompleteReportForMultipleDevices(data),
    refetchOnWindowFocus: false,
    enabled: true,
  });
};

export const addCompanyProfile = async (
  profile: CustomerDetail
): Promise<boolean> => {
  const response = await axiosClient.post<{ value: boolean }>(
    "api/AddCompanyProfile?code=" + API_CODE,
    profile
  );
  const updatedValue = response.data.value;
  return updatedValue;
};

export const reportShare = async (
  data: TcoReportPayloadList
): Promise<string> => {
  const response = await axiosClient.post<string>(
    "api/ReportShare?code=" + API_CODE,
    data
  );
  const updatedValue = response.data;
  return updatedValue;
};

export const getReportShare = async (
  id: string
): Promise<string> => {
  const response = await axiosClient.get<string>(
    `api/GetReportShare?Identifier=${id}&code=` + API_CODE
  );
  const updatedValue = response.data;
  return updatedValue;
};

export const getOnePagerArguments = async (tcoReportPayloadList: TcoReportPayloadList,tcoReportPayload: TcoReportPayload, currency: Currency, selectedYears: number) => {
  const perDevicePayloadList = {
    payloadList: tcoReportPayloadList.payloadList.map((device) => ({
      ...device,
      deviceMixPortfolio : device.deviceMixPortfolio.map(d => {return {...d, quantity: 1}}),
    })),
  }
  const perDeviceData = await getTcoCompleteReportForMultipleDevices(perDevicePayloadList);

  const singleDevicePayloadList = {
    payloadList: tcoReportPayloadList.payloadList.filter(x => x.deviceMixPortfolio.length > 0).slice(0, 1)
    .map((device) => ({
      ...device,
      deviceMixPortfolio : device.deviceMixPortfolio.map(d => {return {...d, quantity: 1}}),
    })),
  }
  const singleDeviceData = await getTcoCompleteReportForMultipleDevices(singleDevicePayloadList);

  const updatedFinalReport = updateTcoCompleteReport(singleDeviceData.mergedResponse, tcoReportPayload, currency, selectedYears)
  
  return {
    perDeviceData,
    updatedFinalReport
  }
}
