import { FC, useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import {
  Dropdown,
  Option,
  DropdownProps,
  OptionProps,
  makeStyles,
  SelectionEvents,
  OptionOnSelectData,
  InfoLabel,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  dropDown: {
    transition: "min-width 0.3s ease",
    "& .f1exfvgq": {
      minWidth: "230px !important",
      "@media (max-width: 626px)": {
        minWidth: "auto !important",
      },
    },
  },
  truncatedText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  listBox: {
    maxHeight: "250px !important",
  },
});

export interface IDropdownOption {
  id: string;
  name: string;
}

interface LabelledDropdownProps {
  info?: string;
  label: string;
  isRequired?: boolean;
  optionProp?: OptionProps;
  dropdownProp?: DropdownProps;
  options: IDropdownOption[];
  onSelect?: (event: SelectionEvents, data: OptionOnSelectData) => void;
}

const LabelledDropdown: FC<LabelledDropdownProps> = (props) => {
  const {
    label,
    isRequired,
    optionProp,
    dropdownProp,
    options,
    onSelect,
    info,
  } = props;
  const [value, setValue] = useState<string>("");
  const onOptionSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
    data.optionText && setValue(data.optionText);
    onSelect && onSelect(event, data);
    setSelectedOptions(data.selectedOptions);
  };
  const styles = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
      setValue(() => dropdownProp?.value ?? "")
      setSelectedOptions([dropdownProp?.value ?? ""])
  },[dropdownProp?.value])

  return (
    <Stack
      verticalFill
      tokens={{ childrenGap: 8 }}
      style={{ maxWidth: "261px" }}
      className={styles.dropDown}
    >
      <InfoLabel
        required={isRequired}
        size={info ? "small" : "medium"}
        info={info ?? ""}
      >
        {label}
      </InfoLabel>
      <Dropdown
        {...dropdownProp}
        aria-label={label}
        onOptionSelect={onOptionSelect}
        title={label}
        button={
          value ? (
            <span className={styles.truncatedText}>{value}</span>
          ) : undefined
        }
        positioning={{ position: "below", autoSize: "height" }}
        listbox={{ className: styles.listBox }}
        value={value}
        selectedOptions={selectedOptions}
      >
        {options.map((option) => (
          <Option {...optionProp} key={option.id}>
            {option.name}
          </Option>
        ))}
      </Dropdown>
    </Stack>
  );
};

export default LabelledDropdown;
